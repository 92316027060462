import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Slide, ToastContainer } from 'react-toastify';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ConnectKitProvider, getDefaultConfig } from 'connectkit';
import { createConfig, http, WagmiProvider } from 'wagmi';
import { bsc, bscTestnet } from 'wagmi/chains';

import { RootRouter } from 'infrastructure/router';

import 'react-toastify/dist/ReactToastify.css';
import 'react-phone-number-input/style.css';

import { loader, SNLogin } from 'pages/auth/Sn-Login.page';

import { Loader } from 'components/atoms';

import { isDevConfig } from 'const';

const projectId = '5b86d63e059e396ce38684e72b6d7410';

export const config = createConfig(
  getDefaultConfig({
    chains: [isDevConfig ? bscTestnet : bsc],
    transports: {
      // RPC URL for each chain
      // [mainnet.id]: http(
      //   `https://eth-mainnet.g.alchemy.com/v2/${process.env.NEXT_PUBLIC_ALCHEMY_ID}`
      // ),
      [isDevConfig ? bscTestnet.id : bsc.id]: http(
        isDevConfig
          ? 'https://data-seed-prebsc-1-s1.binance.org:8545'
          : 'https://bsc-dataseed.binance.org/',
      ),
    },
    walletConnectProjectId: projectId,
    appName: 'SmartX',
    appDescription: 'SmartX',
    appUrl: 'https://dashboard.smart-x.world/favicon.ico', // your app's url
    appIcon: 'https://dashboard.smart-x.world/icons/logo.svg', // your app's icon, no bigger than 1024x1024px (max. 1MB)
  }),
);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 1,
    },
  },
});

const router = createBrowserRouter([
  { path: '*', Component: RootRouter },
  // social login redirect logic
  { path: '/auth/sn_login', Component: SNLogin, loader },
]);

function App() {
  return (
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        {/* TODO: ubery naxyu etu xyiny. */}
        <ConnectKitProvider>
          <ToastContainer
            transition={Slide}
            position="bottom-right"
            autoClose={6000}
            icon={false}
            closeButton={true}
            draggable={false}
            className={'bg-black'}
            theme="dark"
          />
          <RouterProvider
            router={router}
            fallbackElement={<Loader />}
          />
        </ConnectKitProvider>
      </QueryClientProvider>
    </WagmiProvider>
  );
}

export default App;
