import React from 'react';
import { toast } from 'react-toastify';
import { useMutation } from '@tanstack/react-query';
import classNames from 'classnames';

import { unlockWithdrawProfit } from 'infrastructure/api/requests';
import { useUserContext } from 'infrastructure/features/common/context/user.context';

import { InfoIcon } from 'components/Icons';

import { Button } from '../atoms/Button';

export const AffiliateSummary = () => {
  const { user, isBannedUser } = useUserContext();

  const { isLoading: isUnlockingProfit, mutate } = useMutation({
    mutationFn: unlockWithdrawProfit,
    onSuccess: () => {
      toast.success('Profit were withdrawn');
    },
    onError: (error) => {
      // @ts-expect-error
      const errResponse = error.response;

      if (errResponse.status === 418) {
        toast.error(errResponse.data.message);
      } else {
        toast.error('Error while withdrawing');
      }
    },
  });

  const handleWithdrawProfit = () => {
    if (isBannedUser) return;
    mutate();
  };

  const availableBinaryBonus = (user && +user.availableBinaryBonus) ?? 0;

  return (
    <>
      <div className="col-span-1 border-b border-lightBlack p-5 md:border-b-0 md:border-r">
        <h2 className="mb-2 text-sm text-[#9B9B9B]">Total referral bonuses</h2>
        <p className="text-xl font-semibold text-main">
          ${user?.totalReferralBonuses}
        </p>
        <p className="mb-2 mt-2 text-sm text-[#9B9B9B]">Available for claim</p>
        <p className="text-xl font-semibold text-main">
          ${user?.availableBinaryBonus}
        </p>

        <Button
          type="button"
          className="mt-5 bg-main font-semibold text-black disabled:opacity-85 md:mt-[40px]"
          onClick={handleWithdrawProfit}
          isLoading={isUnlockingProfit}
          disabled={availableBinaryBonus < 10}
        >
          Claim Binary Bonus
        </Button>
        {availableBinaryBonus <= 10 && (
          <div className="mt-3 flex items-center">
            <p className="mr-[6px] text-[10px] font-semibold text-grey">
              Available after accumulating $10 or more
            </p>
            <InfoIcon />
          </div>
        )}

        <div
          className={classNames(
            'mt-4 rounded-lg bg-lightBlack p-3 text-center font-semibold text-white',
          )}
        >
          Your LVL: TIER {user?.binaryTier.level}
        </div>
      </div>
      <div className="grid grid-cols-2">
        <div className="col-span-1 border-b border-lightBlack p-5">
          <h2 className="mb-2 text-sm text-[#9B9B9B]">Binary income</h2>
          <p className="font-semibold text-white">${user?.binaryIncome}</p>
        </div>
        <div className="col-span-1 border-b border-l border-lightBlack p-5">
          <h2 className="mb-2 text-sm text-[#9B9B9B]">Direct bonus</h2>
          <p className="font-semibold text-white">${user?.directBonus}</p>
        </div>
        <div className="col-span-1 p-5">
          <h2 className="mb-2 text-sm text-[#9B9B9B]">Binary turnover</h2>
          <p className="font-semibold text-white">${user?.binaryTurnover}</p>
        </div>
        <div className="col-span-1 border-l border-lightBlack p-5">
          <h2 className="mb-2 text-sm text-[#9B9B9B]">Your senior partner</h2>
          <p className="font-semibold text-white">{user?.referred_by ?? '-'}</p>
        </div>
      </div>
    </>
  );
};
