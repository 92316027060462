export enum FinancialTransactionStatus {
  PENDING = 1,
  COMPLETED = 2,
  FAILED = 3,
  CANCELLED = 4,
  DECLINED = 5,
}

export enum FinancialTransactionType {
  DEPOSIT = 1,
  WITHDRAW = 2,
}

export interface TransactionProps {
  amount: string;
  currencyAmount: string;
  currency: string;
  type: FinancialTransactionType;
  status: FinancialTransactionStatus;
  createdAt: string;
  currencyName: string;
  walletHash: string;
  txnId: string;
}

export interface TransactionP2PProps {
  senderPayId: string;
  recipientPayId: string;
  amount: string;
  // TODO: change to enum,when using it
  status: number;
  createdAt: string;
}

export enum TransferTabsProps {
  USER = "users",
  BALANCES = "balances",
}

export interface InvestmentProps {
  id: number;
  amount: number;
  startDate: string | null;
  endDate: string | null;
  isActive: boolean;
  createdAt: string;
  earned: number;
  expected: number;
  isClosed: boolean;
  accrued: string;
}

export interface InvestmentsSummaryProps {
  currentDateTime: string;
  totalInvested: number;
  totalEarned: number;
  total: number;
}

export interface TransactionDataProps {
  status: string;
  qr_code: string;
  expire_utc: string;
  wallet_hash: string;
  amount: string;
  id: number;
  currency: string;
}

export interface PaymentTransactionProps {
  transaction: number;
  data: TransactionDataProps;
}

export enum FinanceTabType {
  DEPOSIT = "deposit",
  ACTIVATE = "activate",
}

export enum WithdrawType {
  SYSTEM = "system",
  EXTERNAL = "external",
}

export enum WalletTypes {
  BALANCE = "balance",
  DIVIDEND = "dividend",
}
