import {
  FaqIcon,
  FinanceIcon,
  HomeIcon,
  ReferralIcon,
  SettingsIcon,
} from 'components/Icons';

import { ROUTES } from 'const/routes';

export const mobileMenuItems = [
  {
    icon: <HomeIcon />,
    title: 'Dashboard',
    link: ROUTES.home,
  },
  {
    icon: <FinanceIcon />,
    title: 'Finance',
    link: ROUTES.finance,
  },
  {
    icon: <ReferralIcon />,
    title: 'Referral program',
    link: ROUTES.affiliate,
  },
  {
    icon: <FaqIcon />,
    title: 'FAQ',
    link: ROUTES.faq,
  },
  {
    icon: <SettingsIcon />,
    title: 'Settings',
    link: ROUTES.settings,
  },
];
