export const ROUTES = {
  home: '/',
  auth: '/auth/',
  login: '/auth/login',
  signUp: '/auth/sign-up',
  settings: '/settings',
  profile: '/profile',
  finance: '/finance',
  affiliate: '/affiliate',
  mine: '/mine',
  faq: '/faq',
  terms: '/terms-of-services',
};
