import { useQueryClient } from "@tanstack/react-query";

export const useQueryClientInstance = () => {
  const queryClient = useQueryClient();

  const invalidateQueryCache = (queryKey: string[]) =>
    queryClient.invalidateQueries({ queryKey, exact: true });

  return {
    queryClient,
    invalidateQueryCache,
  };
};
