import { SocialAuth } from 'components/organisms';

const Login = () => {
  return (
    <div className="w-[320px] md:w-auto">
      <a
        href="https://smart-x.world"
        rel="noreferrer"
        target="_blank"
      >
        <img
          className="m-auto mb-8 w-[200px] md:hidden"
          src="/icons/logo.svg"
          alt="SmartX logo"
        />
      </a>

      <h3 className="mb-8 text-center text-[27px] leading-8 text-white">
        Start Your
        <br />
        Investment Today
      </h3>

      <div className="flex w-full items-center justify-center [&>*]:mt-4">
        {/* <MetaMaskButton theme={"light"} color="white"></MetaMaskButton> */}
        <SocialAuth />
      </div>
    </div>
  );
};

export default Login;
