import Cookies from 'js-cookie';

import { Auth } from 'lib/types';

export const extractReferralCode = (input: string) => {
  const regex = /(?:\?ref=)?([LR]_[a-zA-Z0-9]+)/;
  const match = input.match(regex);

  if (match) {
    return match[1];
  }

  return null;
};

export const isTokenExpired = (token: string) => {
  if (!token) return true;

  const tokenPayload = JSON.parse(atob(token.split('.')[1]));
  const tokenExpiration = tokenPayload.exp;

  return new Date().getTime() / 1000 > tokenExpiration;
};

export const clearSession = () => {
  Cookies.remove(Auth.TOKEN);
  Cookies.remove(Auth.PHPSESSID);
  Cookies.remove(Auth.REFRESH);
  Cookies.remove(Auth.TOTP);
  window.location.reload();
};

export const parseJwt = (token: string) => {
  if (!token) return null;

  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join(''),
  );

  return JSON.parse(jsonPayload);
};
