import { FC, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useQuery } from '@tanstack/react-query';
import classNames from 'classnames';

import { findNodeAndTrimTree, moveUpTreeLevel } from 'domain/services';
import { getSearchReferralUser } from 'infrastructure/api/requests';
import { useToggle } from 'infrastructure/features/common/hooks';

import { DebouncedInput, Loader, Modal, Portal } from 'components/atoms';
import { OrganizationalTree } from 'components/molecules';

import { Nullable, ReferralTreeDataProps } from 'lib/types';

// import { ReferralTreeMobile } from './ReferralTreeMobile';

interface ReferralGraphTreeProps {
  tree: ReferralTreeDataProps;
  binaryStats: any;
  isReferralsLoadingUserStats: boolean;
}

export const ReferralGraphTree: FC<ReferralGraphTreeProps> = ({
  tree,
  binaryStats,
  isReferralsLoadingUserStats,
}) => {
  const [selectedReferral, setSelectedReferral] = useState<Nullable<any>>(null);
  const [selectedTreeElement, setSelectedTreeElement] =
    useState<Nullable<any>>(null);
  const [showReferralModal, toggleReferralModal] = useToggle();

  const { getValues, setValue, watch } = useFormContext();

  watch('query');

  const {
    data: dataSearchReferralUsers,
    isLoading: isSuggestionsLoading,
    refetch,
  } = useQuery({
    queryKey: ['searchReferrals', getValues('query')],
    queryFn: getSearchReferralUser,
    enabled: getValues('query').length > 0,
  });

  const handleSelectReferral = (referralData: any) => {
    setSelectedReferral(referralData);
    toggleReferralModal();
  };

  const handleSelectUser = (node: any) => {
    setSelectedTreeElement(node);

    if (node) {
      setValue('selectedTreeUser', node.id);
    } else {
      setValue('selectedTreeUser', null);
    }
  };

  const handleMoveUpTreeLevel = () => {
    // TODO: get top referral tree element and compare whether it's top or not.
    moveUpTreeLevel(tree, selectedTreeElement, handleSelectUser);
  };

  const trimmedTree = findNodeAndTrimTree(
    tree,
    selectedTreeElement ? selectedTreeElement.id : tree.id,
    4,
  );

  const renderSearchList = () => {
    const suggestionList: { id: number; login: string; email: string }[] =
      Object.values(dataSearchReferralUsers?.data ?? {});

    return (
      <div className="absolute z-20 h-[150px] w-full overflow-y-auto bg-[#2D3037]">
        {isSuggestionsLoading && <Loader />}
        {!isSuggestionsLoading && suggestionList.length === 0 ? (
          <p className="mt-5 text-center">No data to show</p>
        ) : (
          suggestionList.map((suggestion) => {
            return (
              <div
                key={suggestion.id}
                onClick={() => {
                  setValue('query', '');
                  setSelectedTreeElement(suggestion);

                  setValue('selectedTreeUser', suggestion.id);
                }}
                className="cursor-pointer p-4"
              >
                <p className="">{suggestion.login}</p>
                <p className="text-white">{suggestion.email}</p>
              </div>
            );
          })
        )}
      </div>
    );
  };

  const handleDebouncedSearch = (value: string | number) => {
    setValue('query', value as string);
    refetch();
  };

  return (
    <>
      <div className="flex items-center justify-center">
        <div className="relative w-full max-w-[800px]">
          <DebouncedInput
            type="text"
            className="w-full rounded-[4px] bg-[#2D3037] px-4 py-[13px] pr-[45px] text-[#F6EC9B] outline-none transition-all placeholder:text-[#646464]"
            placeholder="Search: Login or Email"
            value={getValues('query')}
            onChange={handleDebouncedSearch}
            debounce={1500}
          />
          <img
            className="pointer-events-none absolute right-[16px] top-1/2 -translate-y-1/2"
            src="/icons/searchIcon.svg"
            alt="search icon"
          />
          {getValues('query').length > 0 && renderSearchList()}
        </div>
      </div>
      <div className="my-[40px] flex flex-col items-center justify-center">
        <p className="mb-2 text-center text-[10px] text-[#404040]">
          Tree Navigation
        </p>
        <button
          type="button"
          className={classNames(
            'flex h-[40px] w-[40px] cursor-pointer items-center justify-center rounded-[4px] border border-lightBlack transition-all disabled:cursor-default hover:[&>svg]:stroke-red',
            selectedTreeElement &&
              'bg-[#2D3037] hover:border-main hover:bg-dMain active:bg-main',
          )}
          disabled={!selectedTreeElement}
          onClick={handleMoveUpTreeLevel}
        >
          <svg
            width="10"
            height="6"
            viewBox="0 0 10 6"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.646115 4.64538L4.49774 0.793762L4.50087 0.793762L4.64731 0.647315C4.84268 0.451953 5.16047 0.451953 5.35583 0.647315L9.35583 4.64732C9.4999 4.79139 9.54188 5.0039 9.46347 5.19208C9.38417 5.38241 9.20262 5.50314 9.00001 5.50314L1.00001 5.50314C0.79963 5.50314 0.615508 5.38158 0.536548 5.19208C0.459346 5.00679 0.500868 4.79285 0.645318 4.64619C0.645583 4.64592 0.645849 4.64565 0.646115 4.64538Z"
              stroke={selectedTreeElement ? '#666C72' : '#2D3037'}
            />
          </svg>
        </button>
      </div>
      {/* hidden lg:block */}
      <div className="mt-[40px] w-full overflow-x-auto">
        <OrganizationalTree
          handleSelectReferral={handleSelectReferral}
          setSelectedTreeElement={handleSelectUser}
          data={trimmedTree}
          selectedTreeElement={selectedTreeElement}
          topUser={tree}
          binaryStats={binaryStats}
          isReferralsLoadingUserStats={isReferralsLoadingUserStats}
        />
      </div>
      {/* <div className="lg:hidden">
        <ReferralTreeMobile treeData={trimmedTree} />
      </div> */}
      <Portal id="infoReferralUser">
        <Modal
          className="bg-none !p-0"
          open={showReferralModal}
          handleClose={toggleReferralModal}
          showCloseButton={false}
        >
          {selectedReferral && (
            <div className="m-auto w-[320px] rounded-lg bg-[#212229] p-6">
              <div>
                <div className="mb-2 flex items-center justify-between">
                  <p className="overflow-hidden text-ellipsis font-semibold text-[#fff]">
                    {selectedReferral.login}
                  </p>
                </div>
                <p className="overflow-hidden text-ellipsis text-left text-xs text-[#9B9B9B]">
                  {selectedReferral.email}
                </p>
              </div>
              <div className="my-5 h-[1px] w-full bg-lightBlack"></div>
              <div className="flex items-center">
                <div>
                  <p className="text-[#9B9B9B]">Tier</p>
                  <h2 className="text-main">{selectedReferral.tier}</h2>
                </div>
                <div className="mx-2 h-[45px] w-[1px] bg-lightBlack md:mx-4" />
                <div>
                  <p className="text-[15px] text-[#9B9B9B]">Binary Turnover</p>
                  <h2 className="text-white">${selectedReferral.turnover}</h2>
                </div>
                <div className="mx-2 h-[45px] w-[1px] bg-lightBlack md:mx-4" />
                <div>
                  <p className="text-[#9B9B9B]">Balance</p>
                  <h2 className="text-white">${selectedReferral.balance}</h2>
                </div>
              </div>
            </div>
          )}
        </Modal>
      </Portal>
    </>
  );
};
