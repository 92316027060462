function TermsOfServices() {
  return (
    <div className="min-h-screen w-full bg-mainBg">
      <div className="m-auto w-full max-w-3xl px-2 py-16 text-justify text-white">
        <h1 className="pb-20 text-center text-3xl text-main">
          Terms and Conditions
        </h1>
        <p className="pb-10">
          These terms are a set of all the information about us, our services,
          legal and financial circumstances of cooperation through our website.
          These terms are implemented to all business operations carried out
          since this day.
        </p>
        <p className="pb-10">
          By ordering any of our services, you automatically agree to the terms
          and conditions described below. Therefore, before entering into a
          contract we strongly recommend that you read the terms and make sure
          you understand all items. In the future, all aspects of our
          cooperation will be based on these terms.
        </p>
        <p className="pb-10">
          If you agree with the terms stated below, then please click "I accept"
          and continue working on our website. If you refuse to accept the terms
          further cooperation with us is not possible and we will not be able to
          provide you with our services.
        </p>
        <p className="pb-10">
          The following terms may be partially modified in compliance with the
          mutual interests of the parties. We are open to suggestions and
          comments from you that do not violate the law and do not infringe the
          interests of other customers.
        </p>
        <p className="pb-10">
          For further reading and use of this document, we suggest that you keep
          a copy of this page to the hard disk or have it printed.
        </p>
        <p className="pb-10">
          In accordance with Section 2.2 we can change the terms unilaterally
          without written notice. Before entering into a contract please read
          the latest version of the terms published on this page.
        </p>
        <p className="pb-10">
          All information provided on this website is for educational purposes
          only in the area of financial market and does not serve in anyway as
          specific investment recommendations, trading recommendations, analysis
          of investment opportunities or similar general recommendations
          regarding the trading of investment instruments. The Company does not
          provide investment services within the meaning of MIFID II The Company
          is not a licensed investment services provider (securities
          broker-dealer) within the meaning of MIFID II.
        </p>
        <p className="pb-10">
          All actions on the platform made available as part of the services
          provided by the Company, although it may be based on real data.
        </p>
        <p className="pb-10">
          The technical solution offered in the form of platforms made available
          as part of the services offered by the Company has aright to use third
          party services to full fill club membership obligations. The website
          is operated and owned by the Company and all content is copyrighted by
          the Company.
        </p>
        <h2 className="pb-10 text-main">1. Definitions and terms</h2>
        <p className="pb-5">
          1.1. Hereinafter referred to as the “Company”, “SmartX” is a title of
          SmartX.
        </p>
        <p className="pb-5">
          1.2. Hereinafter referred to as the “Member”, “User”, “Client” is an
          individual of majority age who is registered on SmartX and is a member
          of the SmartX system.
        </p>

        <p className="pb-5">
          1.3. Hereinafter referred to as the “Resource”, “Site”, “Website” is
          the SmartX online platform, created for the cooperation between the
          Company and the Users.
        </p>
        <p className="pb-5">
          1.4. “Content of the site” is any information and content provided by
          the Company under this Agreement, including all training materials on
          SmartX. The content of the Site includes videos, informative articles,
          audio and graphic materials, user manuals, etc.
        </p>
        <p className="pb-10">
          1.5. “Data” is any information about the User or Company, as well as
          information obtained as a result of cooperation and use of the
          Company's Website by its clients.
        </p>

        <h2 className="pb-10 text-main"> 2. General terms</h2>

        <p className="pb-5">
          2.1. By registering in the system, the User confirms his / her
          acceptance of the terms of this Agreement in full.
        </p>
        <p className="pb-5">
          2.2. The company management reserves the right to amend and extend the
          existing rules unilaterally, without prior notice to participants.
        </p>

        <p className="pb-5">
          2.3. This Agreement is a legally binding treaty between the User and
          the Company and governs the use of the SmartX online platform.
        </p>

        <p className="pb-5">
          2.4. The user is not allowed to use the Company's data in
          spam-mailings or advertising. This information can only be used within
          the bounds of fair cooperation between the Client and the Company.
        </p>

        <p className="pb-5">
          2.5. The user can hold correspondence with the Support Service
          specialists only on issues directly related to this Agreement and the
          Company.
        </p>

        <p className="pb-5">
          2.6. The company is not responsible for failure to fulfill
          obligations, in cases where it was caused by force majeure.
        </p>

        <p className="pb-5">
          2.7. The company adheres to its commitments and maintains a high level
          of compliance with the conditions corresponding to the chosen status,
          impeccably and in full accordance with them.
        </p>

        <p className="pb-5">
          2.8. The content of the Site is not a direct investment recommendation
          and does not contain calls to action.
        </p>
        <p className="pb-5">
          2.9. The Company has the right to provide the Client with any
          information about its activity, changes, events, news and any other
          information related to cooperation through email notifications.
        </p>
        <p className="pb-5">
          2.10. Any individual who is at least 18 years old has the right to
          receive personalized access to the Site and use the services provided
          by the Company.
        </p>
        <p className="pb-10">
          2.11. The withdrawal of funds is carried out only by the
          administration after manual verification of the request. Withdrawals
          can only be requested to the account that was used to replenish the
          balance for investing.
        </p>
        <h2 className="pb-10 text-main">3. Site Terms</h2>

        <p className="pb-5">
          3.1. The full version of the company's website and its content is not
          available to the general public. The Company grants the registered
          User the right to access and use the Site and its content in
          accordance with the terms of this Agreement for the purposes indicated
          in this Agreement.
        </p>
        <p className="pb-5">
          3.2. All data provided to the User within the bounds of cooperation
          can be used for personal purposes and can't be transferred to third
          parties. In case of violation of this clause, cooperation with the
          User will be terminated.
        </p>
        <p className="pb-5">
          3.3. In the case of Multiple accounts (multiple registration of one
          user under different logins), the Company reserves the right to freeze
          the funds and block the User accounts.
        </p>
        <p className="pb-5">
          3.3. In the case of Multiple accounts (multiple registration of one
          user under different logins), the Company reserves the right to freeze
          the funds and block the User accounts.
        </p>
        <p className="pb-5">
          3.4. Account belongs only to its owner. The investor must ensure the
          confidentiality of the login, password and other data specified during
          registration. Identification data transmission to third parties is
          prohibited.
        </p>
        <p className="pb-5">
          3.5. The member has no right to modify the Site Content, take actions
          aimed at reducing the system performance, distortion and destruction
          of the original Site Content, as well as violate its functionality,
          use malware or impede the Company's cooperation with other Investors.
        </p>
        <p className="pb-10">
          3.6. The registered user agrees that the use of the Resource does not
          give him / her the intellectual property rights of the Site.
        </p>
        <h2 className="pb-10 text-main">4. Confidentiality</h2>
        <p className="pb-5">
          4.1. The User's personal data is processed in accordance with the
          Company's privacy policy, which is an integral part of this Agreement.
        </p>
        <p className="pb-5">
          4.2. The company guarantees complete confidentiality and protection of
          personal data provided by the User.
        </p>
        <p className="pb-10">
          4.3. The member has the right to personally make certain of the
          correctness of the use of the personal data provided to the Company by
          making an appropriate request to the Support Service.
        </p>
        <h2 className="pb-10 text-main">5. Termination of the Agreement</h2>
        <p className="pb-5">
          5.1. The Company has the right to terminate this Agreement or
          cooperation without prior notice to the User in the event that he /
          she violates the provisions of this Agreement.
        </p>
        <p className="pb-10">
          5.2. After termination of the Agreement, the Company has no
          obligations to the Members and does not accept any claims regarding
          the refunds.
        </p>
        <h2 className="pb-10 text-main">6. Responsibility</h2>
        <p className="pb-5">
          6.1. By registering, the User agrees that the Company is not
          responsible for:
        </p>
        <p className="pb-5">
          6.1.1. Losses associated with no profits accruing through no fault of
          the company, but due to force majeure circumstances (force majeure);
        </p>
        <p className="pb-5">
          6.1.2. Unauthorized use of a user account or modification of Data by
          third parties;
        </p>
        <p className="pb-5">
          6.1.3. Loss of profit and any other losses incurred in connection with
          use or inability to use the Site;
        </p>
        <p className="pb-5">
          6.1.4. Misunderstanding of the Site's operation by the User;
        </p>
        <p className="pb-5">
          6.1.5. Failures or temporary termination of the communication networks
          or Internet connection on the part of the Member;
        </p>
        <p className="pb-5">
          6.1.6. Failures or temporary suspension of the provision of the Site
          services, including failures caused by the supplier of these services
          on the Company's side;
        </p>
        <p className="pb-5">
          6.1.7. Loss, distortion or damage to the Data, which occurred as a
          result of internal failures in the Site system.
        </p>
        <p className="pb-5">
          6.1.8. Use of the site by third parties using the username and
          password received during registration on the Company’s Website.
        </p>
        <p className="pb-5">
          6.2. All information of the Site does not provide any guarantees of
          any kind, all actions are carried out at the sole discretion of the
          Member.
        </p>
        <p className="pb-5">
          6.3. The Company does not guarantee that the use of the Site will not
          violate the rights of third parties.
        </p>
        <p className="pb-5">
          6.4. The Member agrees to refrain from any negative public statements
          towards the Company and not to make claims for any damage or losses
          incurred as a result of the following actions and factors:
        </p>
        <p className="pb-5">
          6.4.1. Any violation of this Agreement by the Member;
        </p>
        <p className="pb-5">
          6.4.2. Use of the Site by a third party to whom the User voluntarily
          provided personal data or could not ensure their confidentiality;
        </p>
        <p className="pb-5">6.4.3. Use of the Site for illegal purposes.</p>
        <p className="pb-10">
          6.5. SmartX has the right to change the content of the Site and update
          it without prior notice to Users.
        </p>
      </div>
    </div>
  );
}

export default TermsOfServices;
