import { useEffect } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { Outlet, useNavigate } from 'react-router-dom';

import { useUserContext } from 'infrastructure/features/common/context/user.context';
import {
  useActiveWeb3Client,
  useAuth,
} from 'infrastructure/features/common/hooks';

import FACheck from 'pages/2faSecurity.page';

import {
  Loader,
  MaintenanceScreen,
  MobileMenu,
  Sidebar,
} from 'components/atoms';
import { Navbar } from 'components/molecules';

import { ROUTES } from 'const/routes';

const AppLayout = () => {
  const { isWeb3ClientReadyForUse } = useActiveWeb3Client();
  const { TwoFactToken, token, handleSignout } = useAuth();
  const navigate = useNavigate();

  const handleOnIdle = () => {
    handleSignout();
  };

  useIdleTimer({
    onIdle: handleOnIdle,
    timeout: 3600000,
    throttle: 1500,
  });

  const { isProfileLoading, profileError, user, isAppSettingsLoading } =
    useUserContext();

  useEffect(() => {
    // NOTE: if email is empty, redirects to setting until required fields are met.
    if (user?.email === null) {
      navigate(ROUTES.settings);
    }
  }, [user, navigate]);

  if (!isWeb3ClientReadyForUse || isAppSettingsLoading || isProfileLoading)
    return (
      <div className="flex min-h-screen bg-mainBg">
        <Loader className="!bg-white" />
      </div>
    );

  if (profileError) {
    return <MaintenanceScreen />;
  }

  if (TwoFactToken && !token) {
    return <FACheck />;
  }

  return (
    <div className="relative flex overflow-auto lg:min-h-screen">
      <Sidebar />
      <main className="relative mb-0 ml-auto flex w-full flex-col self-stretch overflow-hidden bg-[#131313] px-4 pb-[60px] text-defaultText lg:pt-[30px] xl:w-[calc(100%-212px)] xl:pb-0">
        <Navbar />

        <img
          className="pointer-events-none absolute left-0 top-0 z-0"
          src="/images/decor-header.png"
          alt="decor block."
        />
        <div className="relative z-20 mx-auto w-full max-w-[1440px]">
          <Outlet />
        </div>
        <img
          className="pointer-events-none absolute -bottom-[30px] -right-[60px] z-0"
          src="/images/decor-2.png"
          alt="decor block."
        />
        <div className="mx-auto mt-auto flex w-full max-w-[1440px] items-center justify-between">
          <p className="my-5 text-xs text-white/40">© 2024 Smart-X.world</p>
          <p className="my-5 text-xs text-white/40">v 1.2</p>
        </div>
      </main>
      <MobileMenu />
    </div>
  );
};

export default AppLayout;
