import { ResponseError } from 'lib/types';

export const errorParser = (error: ResponseError): string => {
  const _error = error.response?.data.error;

  if (typeof _error === 'string') return _error;

  if (typeof _error === 'object') {
    const listOfErrors: any = [];

    // @ts-expect-error
    if (!!_error.length) return _error[0];
    // eslint-disable-next-line array-callback-return
    Object.entries(_error as any).map(([key, value]) => {
      // @ts-expect-error
      value.forEach((err) => listOfErrors.push(err));
    });

    return listOfErrors.join(' ');
  }

  return 'Currently undergoing maintenance, please wait until the site update finishes';
};
