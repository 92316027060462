import { FC } from "react";
import classNames from "classnames";

import { DebouncedInput } from "./DebounceInput";
import { SearchIcon } from "../Icons";

interface SearchInputProps {
  value: string;
  onChange: (value: string | number) => void;
  placeholder: string;
  debounce?: number;
  containerClassName?: string;
}

export const SearchInput: FC<SearchInputProps> = ({
  value,
  onChange,
  placeholder,
  debounce = 1000,
  containerClassName,
}) => (
  <div className={classNames("w-[280px] relative", containerClassName)}>
    <DebouncedInput
      className="w-full rounded-xl border border-lightBorder py-[6px] px-4 pr-[40px] outline-none focus:border-secondaryButton transition-all text-white placeholder:text-placeholder placeholder:text-sm disabled:text-lightGrey bg-lBlack"
      value={value}
      placeholder={placeholder}
      onChange={onChange}
      debounce={debounce}
    />
    <div className="absolute top-1/2 -translate-y-1/2 right-4">
      <SearchIcon />
    </div>
  </div>
);
