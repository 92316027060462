import {
  FacebookIconFaq,
  InstagramFaqIcon,
  TelegramFaqIcon,
  WtsFaqIcon,
} from 'components/Icons';

import { MIN_BALANCE_TO_DEPOSITE } from './shared';

export const GENERAL_FAQ = [
  {
    title: 'What is SmartX?',
    body: (
      <p>
        SmartX is an innovative platform based on smart contracts, which
        provides stable earnings and security of financial transactions.
      </p>
    ),
  },
  {
    title: 'How to register on the SmartX website?',
    body: <p>Click 'Connect Wallet' and connect your crypto wallet.</p>,
  },
  {
    title: 'What is registration through a wallet?',
    body: (
      <p>
        This is a method where your account is created and managed using your
        crypto wallet.
      </p>
    ),
  },
  {
    title: 'Which crypto wallets are supported?',
    body: (
      <p>
        <strong>MetaMask, Trust Wallet</strong>, and other wallets compatible
        with the <strong>BNB network</strong> are supported.
      </p>
    ),
  },
  {
    title: 'What to do if you are unable to connect the wallet?',
    body: (
      <div className="[&>*]:mt-5">
        <div className="!mt-0">
          <h4 className="m-1">You should try all login options:</h4>
          <p className="ml-2">- in the wallet's browser</p>
          <p className="ml-2">- in the phone's browser (Chrome, Safari)</p>
          <p className="ml-2">- use a VPN</p>
          <p className="ml-2">- clear cookies.</p>
        </div>
      </div>
    ),
  },
  {
    title: 'Can the connected wallet be changed?',
    body: <p>No, changing the connected wallet is not possible.</p>,
  },
  {
    title: ' What to do in case of losing access to the wallet?',
    body: (
      <p>
        Restore the wallet using the backup phrase or keys. Without restoration,
        access to the account is lost.
      </p>
    ),
  },
  {
    title: 'How safe is registration through a wallet?',
    body: (
      <p>
        Registration through a wallet is safe due to cryptographic technologies.
        Use only official wallet applications.
      </p>
    ),
  },
];

export const FINANCE_FAQ = [
  {
    title: 'What is the minimum investment amount?',
    body: (
      <p>
        Minimum amount for investment{' '}
        <strong>{MIN_BALANCE_TO_DEPOSITE}$</strong>.
      </p>
    ),
  },
  {
    title: 'What is the minimum withdrawal amount?',
    body: (
      <p>
        Minimum amount for withdrawal{' '}
        <strong>{MIN_BALANCE_TO_DEPOSITE}$</strong>.
      </p>
    ),
  },
  {
    title: 'How long does it take to withdraw funds?',
    body: (
      <p>
        Withdrawals are made immediately to your connected wallet after you
        click the 'Claim' button.
      </p>
    ),
  },
  {
    title: 'What currency can I use for investment?',
    body: (
      <p>
        The project works on smart contract in <strong>BNB network</strong> so
        you can make a deposit only <strong>USDT (BNB)</strong>
      </p>
    ),
  },
  {
    title: 'Is early repayment of the invested amount possible?',
    body: (
      <p>
        Your deposit cannot be refunded as the project operates on a smart
        contract and the terms of accrual are specified in an algorithm that
        cannot be changed.
      </p>
    ),
  },
  {
    title: 'How to invest?',
    body: (
      <p>
        After registering in the personal account, click the DEPOSIT button.
        <br />
        In the pop-up window, you need to enter the investment amount (the
        minimum amount is <strong>${MIN_BALANCE_TO_DEPOSITE}</strong>) and click
        the 'DEPOSIT CRYPTO' button.
        <br />
        After receiving a confirmation of the transfer on the blockchain
        network, the funds will be credited to your balance.
      </p>
    ),
  },
  {
    title: 'How much can I earn in a day?',
    body: (
      <p>
        You earn 1% per day of your deposit, accruals occur every 6 hours at
        0.25% after clicking on the “Take profit” button.{' '}
      </p>
    ),
  },
  {
    title: 'How much can I earn from investments?',
    body: (
      <p>
        With SmartX, you can earn +200%, which will ultimately allow you to
        receive daily accruals until your income reaches 300%. For example, if
        you invest $1000, the maximum withdrawal amount will be $3000.
      </p>
    ),
  },
  {
    title: 'What is the withdrawal fee?',
    body: (
      <p>
        The platform deducts a 10% commission on withdrawal. The blockchain
        network commission is paid separately from the cryptocurrency wallet.
      </p>
    ),
  },
  {
    title: 'How to request a withdrawal?',
    body: (
      <p>
        In the dashboard, go to the DASHBOARD tab and click the “Claim” button.
        <br />
        Please note! A 10% commission is deducted on withdrawal.
      </p>
    ),
  },
];

export const REFERRAL_FAQ = [
  {
    title: 'Is there a limitation on the depth of the binary structure?',
    body: (
      <p>Your income from the binary structure has no depth restrictions.</p>
    ),
  },
  {
    title: 'Referral program',
    body: (
      <p>
        Depending on your TIER, you can receive up to 10% from the deposits of
        your direct partners and up to 10% from the turnover of your entire
        structure.
      </p>
    ),
  },
  {
    title: 'Conditions for Binary TIER reward',
    body: (
      <div className="[&>*]:mt-5">
        <div className="!mt-0">
          <h4 className="m-1">Tier 1:</h4>
          <p className="ml-2">- Total binary turnover: less than $10,000</p>
          <p className="ml-2">- Income: 5% from team turnover</p>
        </div>
        <div>
          <h4 className="m-1">Tier 2:</h4>
          <p className="ml-2">- Total binary turnover: $10,000</p>
          <p className="ml-2">- Income: 6% from team turnover</p>
        </div>
        <div>
          <h4 className="m-1">Tier 3:</h4>
          <p className="ml-2">- Total binary turnover: $50,000</p>
          <p className="ml-2">- Income: 7% from team turnover</p>
        </div>
        <div>
          <h4 className="m-1">Tier 4:</h4>
          <p className="ml-2">- Total binary turnover: $100,000</p>
          <p className="ml-2">- Income: 8% from team turnover</p>
        </div>
        <div>
          <h4 className="m-1">Tier 5:</h4>
          <p className="ml-2">- Total binary turnover: $500,000</p>
          <p className="ml-2">- Income: 9% from team turnover</p>
        </div>
        <div>
          <h4 className="m-1">Tier 6:</h4>
          <p className="ml-2">- Total binary turnover: $1,000,000</p>
          <p className="ml-2">- Income: 10% from team turnover</p>
        </div>
      </div>
    ),
  },
  {
    title: 'Conditions for DIRECT TIER reward',
    body: (
      <div className="[&>*]:mt-5">
        <div>
          <h4 className="ml-1">Tier 1:</h4>
          <p className="ml-2">- Personal deposit: less than $300</p>
          <p className="ml-2">- Income: 5% from direct partner's deposit.</p>
        </div>
        <div>
          <h4 className="ml-1">Tier 2:</h4>
          <p className="ml-2">- Total binary turnover: less than $500</p>
          <p className="ml-2">- Income: 6% from direct partner's deposit.</p>
        </div>
        <div>
          <h4 className="ml-1">Tier 3:</h4>
          <p className="ml-2">- Total binary turnover: less than $1000</p>
          <p className="ml-2">- Income: 7% from direct partner's deposit.</p>
        </div>
        <div>
          <h4 className="ml-1">Tier 4:</h4>
          <p className="ml-2">- Total binary turnover: less than $3000</p>
          <p> className="ml-2"- Income: 8% from direct partner's deposit.</p>
        </div>
        <div>
          <h4 className="ml-1">Tier 5:</h4>
          <p className="ml-2">- Total binary turnover: less than $5000</p>
          <p className="ml-2">- Income: 9% from direct partner's deposit.</p>
        </div>
        <div>
          <h4 className="ml-1">Tier 6:</h4>
          <p className="ml-2">- Total binary turnover: more than $5001</p>
          <p className="ml-2">- Income: 10% from direct partner's deposit.</p>
        </div>
      </div>
    ),
  },
  {
    title: 'What % of partner deposits can I receive?',
    body: (
      <p>
        You can receive up to 10% from the turnover of your entire structure.
      </p>
    ),
  },
  {
    title: 'What is "Binary Turnover"?',
    body: (
      <p>
        This is the turnover of the weaker leg, summed up with each collapse of
        the binary cycle.
      </p>
    ),
  },
  {
    title: 'How can I increase my TIER?',
    body: (
      <p>
        Your TIER is determined by the total binary turnover and the size of
        your personal deposit. Increase your binary turnover and deposit to
        raise your TIER level and your percentage of income.
      </p>
    ),
  },
  {
    title: "When is the bonus from a direct partner's deposit paid out?",
    body: (
      <p>
        A direct bonus from each investment made by a partner is paid out
        instantly.
      </p>
    ),
  },
];

export const faqBlocksContacts = [
  {
    title: 'instagram',
    link: 'https://www.instagram.com/smart_xworld/',
    icon: <InstagramFaqIcon />,
  },
  {
    title: 'telegram',
    link: 'https://t.me/smart_x_world',
    icon: <TelegramFaqIcon />,
  },
  {
    title: 'Facebook',
    link: 'https://www.facebook.com/profile.php?id=61564028272309',
    icon: <FacebookIconFaq />,
  },
  {
    title: 'WhatsApp ',
    link: 'https://wa.me/+3168482797',
    icon: <WtsFaqIcon />,
  },
];
