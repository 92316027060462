/* eslint-disable @typescript-eslint/no-unused-vars */
import { useLocation, useNavigate } from 'react-router-dom';
import classNames from 'classnames';

interface SidebarItem {
  item: { title: string; link: string; icon: any };
}

export const SidebarMenuItem = ({ item }: SidebarItem) => {
  const navigate = useNavigate();
  const location = useLocation();

  // in case if childrens should be hightlighted too, use [2] index
  // pass related styles to it
  const isActivePage =
    location.pathname.split('/')[1] === item.link.split('/')[1];

  const { title, link, icon } = item;

  const handleItemClick = () => {
    navigate(link);
  };

  return (
    <li
      className={classNames(
        'relative m-auto flex max-w-[182px] flex-col justify-center rounded-xl text-white transition-all',
        isActivePage ? 'bg-lYellow' : 'hover:bg-white/5 active:bg-white/10',
      )}
    >
      <div
        className="flex w-full cursor-pointer items-center p-3"
        onClick={handleItemClick}
      >
        <div
          className={classNames(
            'mr-2',
            isActivePage ? '[&>svg]:fill-[#000]' : '[&>svg]:fill-white',
          )}
        >
          {icon}
        </div>
        <span
          className={classNames(
            'pointer-events-none text-sm',
            isActivePage && 'text-[#000]',
          )}
        >
          {title}
        </span>
      </div>
    </li>
  );
};
