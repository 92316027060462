import { useUserContext } from 'infrastructure/features/common/context/user.context';

import { BalanceSizeBox } from 'components/atoms';

export const HomeInfoCards = () => {
  const { user } = useUserContext();

  return (
    <div className="grid grid-cols-1 gap-7 md:grid-cols-4">
      <BalanceSizeBox
        title="Total Income"
        size={user?.overallBalance ?? '0'}
      />
      <BalanceSizeBox
        title="Earned Binary Bonuses"
        size={user?.binaryIncome ?? '0'}
      />
      <BalanceSizeBox
        title="Earned Direct Bonuses"
        size={user?.directBonus ?? '0'}
      />

      <div className="rounded-2xl bg-[#F7EA10] p-6">
        <h5 className="mb-2 text-lg font-semibold text-black">Current Tier</h5>
        <div className="flex flex-col">
          <div className="flex items-center justify-between py-4">
            <div className="flex flex-col">
              <h5 className="text-dYellow text-sm">Direct</h5>
              <h6 className="text-lg font-semibold text-[#312F0B]">
                Tier {user?.directTier.level}
              </h6>
            </div>
            <h4 className="text-4xl font-semibold text-[#000]">
              <span className="font-tektur">{user?.directTier.bonus}</span>
              <span className="font-tektur text-lg">%</span>
            </h4>
          </div>
          <div className="h-[1px] w-full bg-[#0000001A]/10"></div>
          <div className="flex items-center justify-between py-4">
            <div className="flex flex-col">
              <h5 className="text-dYellow text-sm">Binary</h5>
              <h6 className="text-lg font-semibold text-[#312F0B]">
                Tier {user?.binaryTier.level}
              </h6>
            </div>
            <h4 className="text-4xl font-semibold text-[#000]">
              <span className="font-tektur">{user?.binaryTier.bonus}</span>
              <span className="font-tektur text-lg">%</span>
            </h4>
          </div>
        </div>
      </div>
    </div>
  );
};
