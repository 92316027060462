import { ValueOf } from 'infrastructure/utilities-types/utilities-types';

export const APP_LANGUAGES = {
  EN: 'en',
  ESP: 'esp',
  FR: 'fr',
  HINDI: 'hindi',
  IND: 'ind',
  URD: 'urd',
  VI: 'vi',
} as const;

export type LanguageValueProp = ValueOf<typeof APP_LANGUAGES>;
