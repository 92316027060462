import { Link } from 'react-router-dom';
import { useQuery } from 'wagmi/query';

import { getReferrals } from 'infrastructure/api/requests';
import { useUserContext } from 'infrastructure/features/common/context/user.context';

import { Loader, ReferralShareInformation } from 'components/atoms';
import { DonutChart } from 'components/molecules';

import { ROUTES } from 'const/routes';

export const HomeReferralInfo = () => {
  const { user, isProfileLoading } = useUserContext();

  const { data: referrals, isLoading: isReferralsLoading } = useQuery({
    queryKey: ['referrals'],
    queryFn: getReferrals,
  });

  // @ts-expect-error
  const leftTurnover = referrals?.leftLegStats.turnover;
  // @ts-expect-error
  const rightTurnover = referrals?.rightLegStats.turnover;

  return (
    <div className="flex flex-col gap-6">
      {isProfileLoading ? (
        <Loader />
      ) : (
        <div className="flex flex-col gap-6">
          <h2 className="text-lg font-semibold text-white">Referral links</h2>
          {+(user?.depositedAmount ?? 0) > 0 ? (
            <>
              <ReferralShareInformation
                withButton
                link={`https://smart-x.world?ref=${user?.referral_codeL}`}
                title="Left Leg"
              />

              <ReferralShareInformation
                withButton
                link={`https://smart-x.world?ref=${user?.referral_codeR}`}
                title="Right Leg"
              />
            </>
          ) : (
            <p className="mt-10 text-center text-main">
              In order to receive referral links, make first{' '}
              <Link to={ROUTES.finance}>
                <strong>deposit</strong>{' '}
              </Link>
            </p>
          )}
        </div>
      )}
      <div className="h-[1px] bg-white/10" />
      <div>
        <DonutChart />
      </div>
      <div className="h-[1px] bg-white/10" />
      {isReferralsLoading ? (
        <Loader />
      ) : (
        <div>
          <div className="md:max-w-auto relative mb-4 flex items-center justify-between">
            <h3 className="max-w-[104px] rounded-lg bg-[#1F1F1F] p-2 text-[#948B2C] md:max-w-full">
              Left Leg Turnover
            </h3>
            <div className="flex items-center">
              <h5 className="mr-1.5 font-tektur text-[22px] font-semibold text-[#FBF395]">
                {leftTurnover}
              </h5>
              <p className="text-sm text-[#948B2C]">USDT</p>
            </div>
          </div>
          <div className="mb-4 flex items-center justify-between">
            <h3 className="max-w-[104px] rounded-lg bg-[#1F1F1F] p-2 text-[#948B2C] md:max-w-full">
              Right Leg Turnover
            </h3>
            <div className="flex items-center">
              <h5 className="mr-1.5 font-tektur text-[22px] font-semibold text-[#FBF395]">
                {rightTurnover}
              </h5>
              <p className="text-sm text-[#948B2C]">USDT</p>
            </div>
          </div>
          <div className="flex items-center justify-between">
            <h3 className="max-w-[104px] rounded-lg bg-[#1F1F1F] p-2 text-[#948B2C] md:max-w-full">
              Size of Direct Team
            </h3>
            <div className="flex items-center">
              <h5 className="mr-1.5 font-tektur text-[22px] font-semibold text-[#FBF395]">
                {user?.referralsCount}
              </h5>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
