import apiInstance from "infrastructure/api";
import {
  ForgotPasswordlEmailValidationSchema,
  LoginValidaionSchema,
  SignUpEmailValidationSchema,
} from "const";
import {
  ForgotPasswordNew,
  LogoutBodyProps,
  SignUpSecondStep,
} from "lib/types";

export const logout = (requestBody: LogoutBodyProps) =>
  apiInstance.post("/logout", requestBody).then((res) => res.data);

export const login = (requestBody: LoginValidaionSchema) =>
  apiInstance.post("/login", requestBody).then((res) => res.data);

// SIGN UP PASSWORD LOGIC
export const signUpStepOne = (requestBody: SignUpEmailValidationSchema) =>
  apiInstance.post("/register", requestBody).then((res) => res.data);

export const signUp = (requestBody: SignUpSecondStep) => {
  const { token, formValues } = requestBody;
  return apiInstance
    .post(`/register/step-two/${token}`, formValues)
    .then((res) => res.data);
};

// FORGOT PASSWORD LOGIC
export const forgotPasswordEmail = (
  formValues: ForgotPasswordlEmailValidationSchema
) => apiInstance.post("/reset-password", formValues).then((res) => res.data);

export const forgotPasswordNew = (requestBody: ForgotPasswordNew) => {
  const { token, formValues } = requestBody;

  return apiInstance
    .post(`/new-password/${token}`, formValues)
    .then((res) => res.data);
};

export const getGoogleUrl = () => {
  return apiInstance.get("/auth/google/url").then((res) => res.data);
};

export const authWithWallet = (requestBody: any) =>
  apiInstance.post("/login", requestBody).then((res) => res);

export const checkUser = (requestBody: any) =>
  apiInstance.post("/check-user", requestBody).then((res) => res.data);
