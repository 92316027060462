import Cookies from 'js-cookie';
import { useAccount } from 'wagmi';

import { clearSession, parseJwt } from 'domain/services';

import { Auth } from 'lib/types';

export const useAuth = () => {
  const { connector } = useAccount();
  const token = Cookies.get(Auth.TOKEN) ?? '';
  const TwoFactToken = Cookies.get(Auth.TOTP);

  const tokenPayload = parseJwt(token);

  const handleExpiredSession = () => {
    clearSession();
  };

  const handleSignout = async () => {
    try {
      handleExpiredSession();
      await connector?.disconnect();
    } catch (error) {
      console.error(error, 'error');
    }
  };

  return { tokenPayload, handleSignout, token, TwoFactToken };
};
