import classNames from "classnames";
import { Loader } from "./Loader";

enum ButtonVariants {
  PRIMARY = "primary",
  SECONDARY = "secondary",
  ACTION = "action",
  DEFAULT = "default",
}

interface ButtonProps {
  type: "button" | "submit" | "reset" | undefined;
  text?: string;
  onClick?: () => void;
  className?: string;
  variant?: ButtonVariants;
  isLoading?: boolean;
  disabled?: boolean;
  children?: any;
}

export const Button = ({
  type,
  text,
  className,
  onClick,
  isLoading = false,
  disabled = false,
  children,
}: ButtonProps) => {
  return (
    <button
      type={type}
      className={classNames(
        // disabled:opacity-80
        "w-full rounded-xl py-4 text-black text-sm  transition-all ease-linear hover:shadow-buttonShadow hover:shadow-main  disabled:hover:shadow-none disabled:bg-opacity-55",
        className && className,
        isLoading && "bg-opacity-70"
      )}
      onClick={onClick}
      disabled={disabled || isLoading}
    >
      {isLoading ? <Loader /> : children ? children : text}
    </button>
  );
};
