import * as z from 'zod';

import { MIN_BALANCE_TO_DEPOSITE } from './shared';

const SPECIAL_SYMBOL_REGEX = /[$&+,:;=?@#|'<>.^*()%!-]/;
// const SPECIAL_SYMBOL_REGEX = /[!"#$%&'()*+,-./:;<=>?@[]^_{|}~]/;
// only LETTERS
const ONLY_CHARACTES = /^[A-Za-zа-я]+$/;

// TODO: make it as one regex
const SYMBOL_LOWER_CASE_REGEX = /[a-zа-я\s]/;
const SYMBOL_UPPER_CASE_REGEX = /[A-ZА-Я\s]/;
const NUMBER_REGEX = /[0-9.-]/;
// const PHONE_REGEX = /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{5}$/;
const TELEGRAM_REGEX = /^[a-zA-Z]\w{4,31}$/;

// consider making separate file for each validation

export const signUpValidation = z.object({
  login: z
    .string()
    .min(5, { message: 'Must be equal or more than 5 symbols' })
    .refine((value) => ONLY_CHARACTES.test(value), {
      message: 'Should only contain latin characters without numbers',
    }),
  email: z.string().min(1, { message: 'Email is required' }).email({
    message: 'Must be a valid email',
  }),
});

export type SignUpValidationSchema = z.infer<typeof signUpValidation>;

export const signUpWithEmail = z.object({
  email: z.string().min(1, { message: 'Email is required' }).email({
    message: 'Must be a valid email',
  }),
});

export type SignUpEmailValidationSchema = z.infer<typeof signUpWithEmail>;

export const loginValidaion = z.object({
  username: z.string().min(1, { message: 'Email is required' }).email({
    message: 'Must be a valid email',
  }),
  password: z.string().min(1, { message: 'Password is required' }),
});

export type LoginValidaionSchema = z.infer<typeof loginValidaion>;

export const forgotPasswordValidation = z
  .object({
    password: z
      .string()
      .min(6, { message: 'Password must be atleast 6 characters' })
      .refine((value) => NUMBER_REGEX.test(value), {
        message: 'Should contain at least one number',
      })
      .refine((value) => SYMBOL_LOWER_CASE_REGEX.test(value), {
        message: 'Should at least one lower case character',
      })
      .refine((value) => SYMBOL_UPPER_CASE_REGEX.test(value), {
        message: 'Should at least one upper case character',
      })
      .refine((value) => SPECIAL_SYMBOL_REGEX.test(value), {
        message: 'Should contain at least one special symbol',
      }),
    password_confirmation: z
      .string()
      .min(1, { message: 'Confirm Password is required' }),
  })
  .refine((data) => data.password === data.password_confirmation, {
    path: ['password_confirmation'],
    message: "Password don't match",
  });

export type ForgotPasswordValidaionSchema = z.infer<
  typeof forgotPasswordValidation
>;

export const forgotPasswordEmailValidation = z.object({
  email: z.string().min(1, { message: 'Email is required' }).email({
    message: 'Must be a valid email',
  }),
});

export type ForgotPasswordlEmailValidationSchema = z.infer<
  typeof forgotPasswordEmailValidation
>;

export const registerValidation = z
  .object({
    referralLink: z
      .string()
      .min(1, { message: 'ReferralLink is required' }) // Проверка длины ссылки (если она указана)
      .optional() // Ссылка опциональна, если skip установлен
      .or(z.literal('')), // Разрешаем пустую строку
    skip: z.boolean(), // Поле "skip" для галочки
  })
  .refine(
    (data) => {
      // Если галочка "skip" не установлена, проверяем, что referralLink не пустой
      if (!data.skip && data.referralLink?.trim() === '') {
        return false; // Ошибка валидации, если "skip" выключен, а поле пустое
      }
      return true; // Валидация успешна в остальных случаях
    },
    {
      message: 'ReferralLink is required', // Сообщение об ошибке
      path: ['referralLink'], // Путь, где ошибка должна отображаться
    },
  );

export const profileInfoValidation = z.object({
  email: z.string().min(1, { message: 'Email is required' }).email({
    message: 'Must be a valid email',
  }),
  login: z.string().min(4, { message: 'Must be equal or more than 4 symbols' }),
  firstName: z
    .string()
    .min(2, { message: 'Must be equal or more than 2 symbols' })
    .refine((value) => ONLY_CHARACTES.test(value), {
      message: 'Should only contain latin characters without numbers',
    }),
  lastName: z
    .string()
    .min(2, { message: 'Must be equal or more than 2 symbols' })
    .refine((value) => ONLY_CHARACTES.test(value), {
      message: 'Should only contain latin characters without numbers',
    }),
  telegram: z
    .string()
    .min(5, { message: 'Telegram username must be min 5 characters' })
    .max(32, { message: 'Telegram username must be max 32 characters' })
    .refine((value) => !/[$@]/.test(value), {
      message: 'Only your username, without @',
    })
    .refine((value) => TELEGRAM_REGEX.test(value), {
      message:
        'It must start with a letter, can only contain letters, numbers, and underscores',
    })
    .optional()
    .or(z.literal('')),
});

export type ProfileInfoValidationSchema = z.infer<typeof profileInfoValidation>;

export const faCodeValidation = z.object({
  pin: z
    .string()
    .min(1, { message: 'Must not be empty' })
    .refine((value) => NUMBER_REGEX.test(value), {
      message: 'Should only contain numbers',
    }),
  totp: z
    .string()
    .min(1, { message: 'Must not be empty' })
    .refine((value) => NUMBER_REGEX.test(value), {
      message: 'Should only contain numbers',
    }),
});

export type FACodeValidationSchema = z.infer<typeof faCodeValidation>;

export const pinVerificationValidation = z.object({
  pin: z
    .string()
    .min(4, { message: 'Pin must be 4 characters' })
    .max(4, { message: 'Pin should be max 4 characters' })
    .refine((value) => NUMBER_REGEX.test(value), {
      message: 'Should contain only numbers',
    }),
});

export type PinVerificationValidationSchema = z.infer<
  typeof pinVerificationValidation
>;

export const securedComponentFormSchema = z
  .object({
    pin: z
      .string()
      .min(4, { message: 'Pin must be 4 characters' })
      .max(4, { message: 'Pin should be max 4 characters' })
      .refine((value) => NUMBER_REGEX.test(value), {
        message: 'Should contain only numbers',
      }),
    totp: z.string(),
    '2faEnabled': z.boolean(),
    pinEnabled: z.boolean(),
  })
  .refine(
    (data) => {
      if (data['2faEnabled']) {
        return !!data.totp;
      }

      // no VALIDATION
      return true;
    },
    {
      message: '2FA code is required',
      path: ['totp'],
    },
  );

export type SecuredComponentValidationSchema = z.infer<
  typeof securedComponentFormSchema
>;

export const pincodeValidation = z
  .object({
    pin: z
      .string()
      .min(4, { message: 'Pin must be 4 characters' })
      .max(4, { message: 'Pin should be max 4 characters' })
      .refine((value) => NUMBER_REGEX.test(value), {
        message: 'Should contain only numbers',
      }),
    pin_confirmation: z.string().min(1, { message: 'Confirm Pin is required' }),
  })
  .refine((data) => data.pin === data.pin_confirmation, {
    path: ['pin_confirmation'],
    message: "Pin don't match",
  });

export type PincodeValidationSchema = z.infer<typeof pincodeValidation>;

export const faCodeSecurityScreenValidation = z.object({
  totp: z
    .string()
    .min(1, { message: 'Must not be empty' })
    .refine((value) => NUMBER_REGEX.test(value), {
      message: 'Should only contain numbers',
    }),
  '2faToken': z
    .string()
    .min(1, { message: 'Must not be empty' })
    .refine((value) => NUMBER_REGEX.test(value), {
      message: 'Should only contain numbers',
    }),
});

export type FaCodeSecurityScreenValidationSchema = z.infer<
  typeof faCodeSecurityScreenValidation
>;

export const depositFormValidation = z.object({
  amount: z
    .string()
    .min(1, { message: 'Amount is required' })
    .refine((value) => NUMBER_REGEX.test(value), {
      message: 'Should only contain numbers',
    })
    .refine(
      (value) => {
        return +value >= MIN_BALANCE_TO_DEPOSITE;
      },
      {
        message: `Should be more $${MIN_BALANCE_TO_DEPOSITE} or more`,
      },
    ),
});

export type DepositFormValidationSchema = z.infer<typeof depositFormValidation>;

export const withdrawFormValidation = z.object({
  currency: z.object({}).required(),
  amount: z
    .string()
    .min(1, { message: 'Amount is required' })
    .refine((value) => NUMBER_REGEX.test(value), {
      message: 'Should only contain numbers',
    })
    .refine(
      (value) => {
        return +value >= 0.1;
      },
      {
        message: 'Should be more than 0',
      },
    ),
  walletAddress: z
    .string()
    .min(1, { message: 'Wallet address is required' })
    .refine((value) => /^\S*$/.test(value), {
      message: 'Should only contain characters, without spaces',
    })
    .refine((value) => !SPECIAL_SYMBOL_REGEX.test(value), {
      message: 'Should not contain special symbols',
    }),
});

export type WithdrawFormValidationSchema = z.infer<
  typeof withdrawFormValidation
>;

export const dividenFormValidation = z.object({
  amount: z
    .string()
    .min(1, { message: 'Amount is required' })
    .refine((value) => NUMBER_REGEX.test(value), {
      message: 'Should only contain numbers',
    })
    .refine(
      (value) => {
        return +value >= 1;
      },
      {
        message: 'Minimum deposit is $1 ',
      },
    ),
});

export type DividenFormValidationSchema = z.infer<typeof dividenFormValidation>;

export const dividendFormActivateValidation = z.object({
  amountToAllocation: z
    .string()
    .min(1, { message: 'Amount is required' })
    .refine((value) => NUMBER_REGEX.test(value), {
      message: 'Should only contain numbers',
    })
    .refine(
      (value) => {
        return +value >= MIN_BALANCE_TO_DEPOSITE;
      },
      {
        message: `Minimum deposit is $${MIN_BALANCE_TO_DEPOSITE}`,
      },
    ),
});

export type DividendFormActivateSchema = z.infer<
  typeof dividendFormActivateValidation
>;

export const p2pFormValidation = z.object({
  payId: z.string().min(1, { message: 'PayID is required' }),
  comment: z.string(),
  amount: z
    .string()
    .min(1, { message: 'Amount is required' })
    .refine((value) => NUMBER_REGEX.test(value), {
      message: 'Should only contain numbers',
    })
    .refine(
      (value) => {
        return +value >= 0;
      },
      {
        message: 'Should be more than 0 ',
      },
    ),
});

export type P2PFormValidationSchema = z.infer<typeof p2pFormValidation>;

export const FromRefToBalanceFormValidation = z.object({
  amount: z
    .string()
    .min(1, { message: 'Amount is required' })
    .refine((value) => NUMBER_REGEX.test(value), {
      message: 'Should only contain numbers',
    })
    .refine(
      (value) => {
        return +value >= 1;
      },
      {
        message: 'Should be more than $1',
      },
    ),
});

export type FromRefToBalanceValidationSchema = z.infer<
  typeof FromRefToBalanceFormValidation
>;
