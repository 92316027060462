import { cloneElement, useState } from 'react';
import classNames from 'classnames';

import { Accordion } from 'components/atoms';
import {
  ComputerIcon,
  FinanceFaqIcon,
  ReferralProgrammIcon,
} from 'components/Icons';

import {
  faqBlocksContacts,
  FINANCE_FAQ,
  GENERAL_FAQ,
  REFERRAL_FAQ,
} from 'const';

const TAB_TYPES = {
  GENERAL: 'gen',
  REFERRAL: 'referral',
  FINANCE: 'finance',
} as const;

export default function FaqPage() {
  const [faqType, setFaqType] = useState<any>(TAB_TYPES.GENERAL);

  const renderQuestionsArray = () => {
    if (TAB_TYPES.REFERRAL === faqType) return REFERRAL_FAQ;

    if (TAB_TYPES.FINANCE === faqType) return FINANCE_FAQ;

    return GENERAL_FAQ;
  };

  return (
    <div className="pb-8">
      <div className="mb-9 text-white">
        {/* <h2 className="text-6xl font-bold">FAQ</h2> */}
        <p className="md:text-2xl">Questions & answers</p>
      </div>
      <ul className="mb-[72px] flex flex-col md:flex-row md:items-center md:[&>*]:ml-4">
        <li
          className="!ml-0 mt-2 flex cursor-pointer md:mt-0 md:items-center md:justify-center"
          onClick={() => setFaqType(TAB_TYPES.GENERAL)}
        >
          <div
            className={classNames(
              faqType === TAB_TYPES.GENERAL
                ? '[&>svg]:!fill-lYellow'
                : '[&>svg]:!fill-defaultText',
            )}
          >
            <ComputerIcon />
          </div>
          <span
            className={classNames(
              'ml-3',
              faqType === TAB_TYPES.GENERAL && 'text-lYellow',
            )}
          >
            General
          </span>
        </li>
        <li
          className="mt-2 flex cursor-pointer md:mt-0 md:items-center md:justify-center"
          onClick={() => setFaqType(TAB_TYPES.REFERRAL)}
        >
          <div
            className={classNames(
              faqType === TAB_TYPES.REFERRAL
                ? '[&>svg]:!fill-lYellow'
                : '[&>svg]:!fill-defaultText',
            )}
          >
            <ReferralProgrammIcon />
          </div>
          <span
            className={classNames(
              'ml-3',
              faqType === TAB_TYPES.REFERRAL && 'text-lYellow',
            )}
          >
            Referral Program
          </span>
        </li>
        <li
          className="mt-2 flex cursor-pointer md:mt-0 md:items-center md:justify-center"
          onClick={() => setFaqType(TAB_TYPES.FINANCE)}
        >
          <div
            className={classNames(
              faqType === TAB_TYPES.FINANCE
                ? '[&>svg]:!fill-lYellow'
                : '[&>svg]:!fill-defaultText',
            )}
          >
            <FinanceFaqIcon />
          </div>
          <span
            className={classNames(
              'ml-3',
              faqType === TAB_TYPES.FINANCE && 'text-lYellow',
            )}
          >
            Finance
          </span>
        </li>
      </ul>
      <div className="mb-[120px] max-w-[670px] [&>*]:mt-4">
        {renderQuestionsArray().map((faqInfo) => {
          return (
            <Accordion
              key={faqInfo.title}
              text={faqInfo.body}
              title={faqInfo.title}
            />
          );
        })}
      </div>
      <div className="relative mb-12">
        <h3 className="mb-10 font-bold text-white md:text-[60px]">Contacts</h3>
        <p className="text-lYellow">
          If you do not find the information you are looking for, please contact
          us:
        </p>
      </div>
      <div className="grid grid-cols-2 gap-6 md:flex md:grid-cols-5 md:flex-row">
        {faqBlocksContacts.map((socialInfo) => {
          return (
            <a
              key={socialInfo.title}
              href={socialInfo.link}
              className="contactsFaqBlock flex flex-col items-center rounded-2xl border border-[#2C3138] md:h-[200px] md:w-[200px]"
              target="_blank"
              rel="noreferrer"
            >
              <div
                className={classNames(
                  socialInfo.title === 'Facebook' &&
                    'mb-5 mt-5 flex items-center justify-center [&>svg]:h-[80px]',
                )}
              >
                {cloneElement(socialInfo.icon)}
              </div>
              <h4 className="uppercase">{socialInfo.title}</h4>
            </a>
          );
        })}
      </div>
    </div>
  );
}
