import { Box } from 'components/atoms';
import { DepositForm } from 'components/molecules';

export const FinanceDepositView = () => {
  return (
    <div className="grid grid-cols-1 gap-5 md:grid-cols-4">
      <Box className="col-span-1 md:col-span-4">
        <DepositForm />
      </Box>
    </div>
  );
};
