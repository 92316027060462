import { FC } from 'react';
import { Navigate, Outlet } from 'react-router';

export const ProtectedRoute: FC<any> = ({
  isAllowed,
  redirectPath = '/auth/login',
  children,
}) => {
  if (!isAllowed) {
    return (
      <Navigate
        to={redirectPath}
        replace
      />
    );
  }

  return children ? children : <Outlet />;
};
