import { useUserContext } from 'infrastructure/features/common/context/user.context';

import {
  ArrowsLeftFinance,
  ArrowsRightFinanace,
  ProfitHand,
} from 'components/Icons';

import { Button } from './Button';

export const FinanceEarnings = () => {
  const { user, withdrawReferralProfit, isLoadingClaimingBinaryBonus } =
    useUserContext();

  const handleWithdrawProfitFromContract = () => {
    user &&
      withdrawReferralProfit(
        user.claimedProfitAmount,
        user.lastProfitClaimedAt,
        user.referralBonusAmount,
      );
  };

  const availableForWithdraw = user ? user.contractWithdrawalAmount : 0;

  return (
    <>
      <div className="absolute bottom-0 left-0 -z-10 hidden md:block">
        <ArrowsLeftFinance />
      </div>
      <div className="mx-auto w-full md:max-w-[252px] md:text-center">
        <h3 className="mb-2 text-sm text-[#948B2C]/60">
          Earnings from Deposit
        </h3>
        <div className="mx-auto mb-4 flex items-center justify-between text-center md:justify-center">
          <h4 className="mr-1 font-tektur text-2xl font-semibold text-[#FBF395]">
            {availableForWithdraw}
          </h4>
          <p className="uppercase text-[#948B2C]/60">usdt</p>
        </div>
        <Button
          type="button"
          className="flex items-center justify-center !rounded-2xl bg-[#FAEF71] py-4 transition-all hover:shadow-main"
          isLoading={isLoadingClaimingBinaryBonus}
          onClick={handleWithdrawProfitFromContract}
          disabled={+availableForWithdraw <= 0}
        >
          <ProfitHand />
          <span className="text-lg font-semibold text-[#000]">
            Claim Profit
          </span>
        </Button>
      </div>
      <div className="absolute bottom-0 right-0 -z-10 hidden md:block">
        <ArrowsRightFinanace />
      </div>
    </>
  );
};
