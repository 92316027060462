import { FC } from "react";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";

import classNames from "classnames";

interface PaginationProps {
  perPage: number;
  total: number;
  totalPages: number;
}

const MAX_NUMBER_TO_SHOW = 7;

function getPaginatedNumbers(
  currentPage: number,
  totalNumbers: number,
  numbersPerPage: number
) {
  const halfPerPage = Math.floor(numbersPerPage / 2);

  let startIndex = currentPage - halfPerPage;
  let endIndex = currentPage + halfPerPage;

  // Ensure that the range does not go beyond the bounds of the total numbers
  if (startIndex < 1) {
    startIndex = 1;
    endIndex = numbersPerPage;
  } else if (endIndex > totalNumbers) {
    endIndex = totalNumbers;
    startIndex = totalNumbers - numbersPerPage + 1;
  }

  // Generate the pagination numbers within the calculated range
  return Array.from(
    { length: endIndex - startIndex + 1 },
    (_, index) => startIndex + index
  );
}

export const Pagination: FC<PaginationProps> = ({
  perPage,
  total,
  totalPages,
}) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const currentPage = searchParams.get("page") ?? 1;

  const handleNavigation = (isNext: boolean) => {
    const nextPage = isNext ? +currentPage + 1 : +currentPage - 1;

    nextPage === 1
      ? searchParams.delete("page")
      : searchParams.set("page", `${nextPage}`);

    const path = {
      pathname: "",
      search: searchParams.toString(),
    };

    navigate(path);
  };

  return (
    <div className="flex items-center justify-between flex-col md:flex-row">
      <div className="text-sm mb-5 md:mb-0">
        Showing {currentPage} to {perPage} of {total} entites
      </div>
      <ul className="flex">
        <button
          onClick={() => handleNavigation(false)}
          disabled={+currentPage === 1}
          type="button"
          className="h-[28px] w-[28px] bg-[#282829] rounded-full flex items-center justify-center hover:bg-lightGrey transition-all disabled:hover:bg-[#282829]"
        >
          <img src="/icons/chevronLeft.svg" alt="arrow left" />
        </button>
        <ul className="flex items-center justify-center h-[28px]  bg-[#282829] rounded-2xl mx-1">
          {getPaginatedNumbers(
            +currentPage,
            totalPages,
            totalPages >= MAX_NUMBER_TO_SHOW ? MAX_NUMBER_TO_SHOW : totalPages
          ).map((pageNumber: number, i) => {
            return (
              <li
                className={classNames(
                  +currentPage === pageNumber && "bg-main text-white",
                  "h-[28px] w-[28px] rounded-full flex items-center justify-center hover:bg-lightGrey transition-all cursor-pointer"
                )}
                key={i}
                onClick={(e) => {
                  // TODO: refactor this
                  e.preventDefault();
                  navigate({
                    pathname: "",
                    search: `page=${pageNumber}`,
                  });
                }}
              >
                {pageNumber}
              </li>
            );
          })}
        </ul>
        <button
          disabled={+currentPage === totalPages}
          type="button"
          className="h-[28px] w-[28px] bg-[#282829] rounded-full flex items-center justify-center hover:bg-lightGrey transition-all disabled:hover:bg-[#282829]"
          onClick={() => handleNavigation(true)}
        >
          <img src="/icons/chevronRight.svg" alt="arrow left" />
        </button>
      </ul>
    </div>
  );
};
