import { Route, Routes } from 'react-router-dom';

import { UserContextProvider } from 'infrastructure/features/common/context/user.context';
import { useAuth } from 'infrastructure/features/common/hooks';

import AffiliatePage from 'pages/affiliate/affiliate.page';
import Login from 'pages/auth/Login.page';
import SignUp from 'pages/auth/SignUp.page';
import FaqPage from 'pages/Faq.page';
import FinancePage from 'pages/finance/Finance.page';
import HomePage from 'pages/Home.page';
import NotFoundPage from 'pages/NotFound.page';
import Settings from 'pages/settings/Profile.page';
import TermsOfServices from 'pages/TermsOfServices.page';

import { ProtectedRoute } from 'components/atoms';
import AppLayout from 'components/layouts/app.layout';
import AuthLayout from 'components/layouts/auth.layout';

import { ROUTES } from 'const/routes';

export const RootRouter = () => {
  const { token } = useAuth();
  const isAuthorized = !!token;

  return (
    <Routes>
      <Route
        path={ROUTES.auth}
        element={
          <ProtectedRoute
            isAllowed={!isAuthorized}
            redirectPath={ROUTES.home}
          >
            <AuthLayout />
          </ProtectedRoute>
        }
      >
        <Route
          path="login"
          element={<Login />}
        />
        <Route
          path="sign-up"
          element={<SignUp />}
        />

        <Route
          path="*"
          element={<NotFoundPage />}
        />
      </Route>

      <Route
        path={ROUTES.terms}
        element={<TermsOfServices />}
      />
      <Route
        element={
          <ProtectedRoute isAllowed={isAuthorized}>
            <UserContextProvider>
              <AppLayout />
            </UserContextProvider>
          </ProtectedRoute>
        }
      >
        <Route path={ROUTES.home}>
          <Route
            index
            element={<HomePage />}
          />
          <Route path={ROUTES.settings}>
            <Route
              index
              element={<Settings />}
            />
            <Route
              path="*"
              element={<NotFoundPage />}
            ></Route>
          </Route>
          <Route path={ROUTES.finance}>
            <Route
              index
              element={<FinancePage />}
            />
            <Route
              path="*"
              element={<NotFoundPage />}
            ></Route>
          </Route>
          <Route path={ROUTES.affiliate}>
            <Route
              index
              element={<AffiliatePage />}
            />
            <Route
              path="*"
              element={<NotFoundPage />}
            ></Route>
          </Route>
          <Route
            path={ROUTES.faq}
            element={<FaqPage />}
          />
        </Route>
      </Route>
      <Route
        path="*"
        element={<NotFoundPage />}
      />
    </Routes>
  );
};
