import classNames from 'classnames';

interface BalanceSizeBoxProps {
  title: string;
  size: string;
  className?: string;
}

export const BalanceSizeBox = ({
  title,
  size,
  className,
}: BalanceSizeBoxProps) => {
  return (
    <div
      className={classNames(
        'balanceBG relative overflow-hidden !rounded-2xl p-6',
        className,
      )}
    >
      <h5 className="mb-2 text-lg font-semibold text-black">{title}</h5>
      <div className="relative z-20 flex flex-col items-end">
        <span className="font-tektur text-4xl font-medium text-black">
          {size}
        </span>
        <span className="text-sm font-normal text-black">USDT</span>
      </div>
      <img
        src="/images/cardDesigns.png"
        className="absolute bottom-0 left-0 hidden w-full md:block"
        alt="cardDesigns"
      />
    </div>
  );
};
