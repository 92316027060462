import dayjs from 'dayjs';

import { useUserContext } from 'infrastructure/features/common/context/user.context';

import { FlipperNumbers } from 'components/atoms';

export const DividendBonusesTicker = () => {
  const { user } = useUserContext();

  const targetTime = dayjs(user?.nextProfitClaimTime);
  const now = dayjs();

  const availableForClaim = targetTime.diff(now, 'millisecond') < 0;

  const withdrawnPercentage = Number(user?.withdrawnPercentage);
  const activeInvestNumber =
    withdrawnPercentage === 100 ? 0 : Number(user?.currentCounterState ?? 0);

  return (
    <div className="flex h-full items-center">
      <div className="relative w-full">
        <div className="relative left-[3px] z-10 font-tektur">
          <FlipperNumbers
            activeInvestment={activeInvestNumber}
            percent={user?.secondRate as string}
            hasAnyActiveInvestments={!availableForClaim}
          />
        </div>
      </div>
    </div>
  );
};
