import React from 'react';
import { Link } from 'react-router-dom';

import { useUserContext } from 'infrastructure/features/common/context/user.context';

import { ROUTES } from 'const/routes';

import { ReferralShareInformation } from './ReferralShareInfo';

export const AffiliateShareLinks = () => {
  const { user } = useUserContext();

  return (
    <>
      {+(user?.depositedAmount ?? 0) > 0 ? (
        <>
          <ReferralShareInformation
            withButton
            link={`https://smart-x.world?ref=${user?.referral_codeL}`}
            title="Share referral link L"
          />
          <div className="mt-5">
            <ReferralShareInformation
              withButton
              link={`https://smart-x.world?ref=${user?.referral_codeR}`}
              title="Share referral link R"
            />
          </div>
        </>
      ) : (
        <p className="mb-10 mt-10 text-center text-main">
          In order to receive referral links, make first{' '}
          <Link to={ROUTES.finance}>
            <strong>deposit</strong>{' '}
          </Link>
        </p>
      )}
    </>
  );
};
