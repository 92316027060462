import { FC } from 'react';

import { EmptyData } from './EmptyData';
import { Loader } from './Loader';

interface ViewContentProps {
  isLoading: boolean;
  isError: boolean;
  children: any;
  data: any;
}

export const ViewContent: FC<ViewContentProps> = ({
  isLoading,
  isError,
  data,
  children,
}) => {
  if (isLoading) return <Loader className="!bg-white" />;

  if (isError)
    return <h2 className="font-semibold text-red">Error while loading data</h2>;

  if (!data || (data && !data.length && !data.id)) return <EmptyData />;

  return children(data);
};
