export const MaintenanceScreen = () => (
  <div className="maintenanceGradient flex h-screen items-center justify-center">
    <div className="m-auto w-full max-w-[1440px]">
      <div className="flex flex-col-reverse items-center justify-between xl:flex-row">
        <div className="flex flex-col items-center xl:items-start">
          <img
            src="/images/smartx-logo.svg"
            alt="smartXLogo"
            className="mb-10 mt-9 w-[248px] xl:mt-0"
          />
          <h2 className="mb-5 text-2xl font-semibold text-white xl:text-5xl">
            Technical <br className="hidden xl:block" /> maintenance
          </h2>
          <p className="text-lg text-white/80 xl:text-2xl">
            We will return soon: <br /> better and updated.
          </p>
        </div>
        <img
          className="max-w-[327px] xl:max-w-[778px]"
          src="/images/works_transp1.png"
          alt="maintenance screen img"
        />
      </div>
    </div>
  </div>
);
