import { Nullable, ReferralTreeDataProps } from 'lib/types';

export const fillEmptyNode = () => ({
  id: null,
  email: '-',
  login: '-',
  firstName: '-',
  lastName: '-',
  leftChild: null,
  rightChild: null,
  hasMoreChildren: false,
});

export const trimAndFillTree: any = (
  node: ReferralTreeDataProps,
  depth: number,
) => {
  if (!node || depth === 0) {
    return null;
  }

  const leftChild = trimAndFillTree(node.leftChild, depth - 1);
  const rightChild = trimAndFillTree(node.rightChild, depth - 1);

  return {
    ...node,
    leftChild: leftChild === null && depth > 1 ? fillEmptyNode() : leftChild,
    rightChild: rightChild === null && depth > 1 ? fillEmptyNode() : rightChild,
    hasMoreChildren: !!node.leftChild || !!node.rightChild,
  };
};

export const fillToDepth: any = (
  node: ReferralTreeDataProps,
  targetDepth: number,
  currentDepth = 1,
) => {
  if (currentDepth >= targetDepth) {
    return node;
  }

  const leftChild = node.leftChild || fillEmptyNode();
  const rightChild = node.rightChild || fillEmptyNode();

  return {
    ...node,
    leftChild: fillToDepth(leftChild, targetDepth, currentDepth + 1),
    rightChild: fillToDepth(rightChild, targetDepth, currentDepth + 1),
    hasMoreChildren: !!node.leftChild || !!node.rightChild,
  };
};

export const findNodeAndTrimTree: any = (
  node: ReferralTreeDataProps,
  selectedId: number,
  depth: number,
) => {
  if (!node) return null;

  if (node.id === selectedId) {
    return fillToDepth(trimAndFillTree(node, depth), depth);
  }

  const leftResult = findNodeAndTrimTree(node.leftChild, selectedId, depth);
  if (leftResult) {
    return leftResult;
  }

  return findNodeAndTrimTree(node.rightChild, selectedId, depth);
};

export const findParentNode = (
  root: Nullable<ReferralTreeDataProps>,
  childId: number,
): any => {
  if (!root || (!root.leftChild && !root.rightChild)) return null;
  if (
    (root.leftChild && root.leftChild.id === childId) ||
    (root.rightChild && root.rightChild.id === childId)
  ) {
    return root;
  }
  return (
    findParentNode(root.leftChild, childId) ||
    findParentNode(root.rightChild, childId)
  );
};

export const moveUpTreeLevel = (
  tree: ReferralTreeDataProps,
  selectedTreeElement: ReferralTreeDataProps,
  handleSelectUser: any,
) => {
  if (selectedTreeElement) {
    const parentNode = findParentNode(tree, selectedTreeElement.id);

    if (parentNode.id === tree.id) {
      handleSelectUser(null);
      return;
    }

    if (parentNode) {
      handleSelectUser(parentNode);
    }
  }
};
