import { FC } from 'react';
import classNames from 'classnames';

import { useToggle } from 'infrastructure/features/common/hooks';

import { ChevronDown } from 'components/Icons';
import { UserInfoBlock } from 'components/molecules';

import { ReferralTreeDataProps } from 'lib/types';

interface InactiveReferralsProps {
  title: string;
  inactiveReferrals: ReferralTreeDataProps[];
}

export const InactiveReferrals: FC<InactiveReferralsProps> = ({
  title,
  inactiveReferrals,
}) => {
  const [showInactive, toggleInactive] = useToggle();

  return (
    <>
      <div
        className="flex cursor-pointer items-center justify-between"
        onClick={toggleInactive}
      >
        <h3 className="font-semibold text-lGrey">{title}</h3>
        <div
          className={classNames(
            'ml-[10px] transition-all',
            showInactive ? 'rotate-0' : 'rotate-180',
          )}
        >
          <ChevronDown />
        </div>
      </div>
      {showInactive && (
        <div className="max-h-[400px] overflow-y-auto pr-5">
          {inactiveReferrals.map((inactiveReferral) => {
            return (
              <div
                key={inactiveReferral.id}
                className="mt-5 flex justify-between rounded-2xl bg-lightBlack p-5"
              >
                <UserInfoBlock
                  key={inactiveReferral.id}
                  showIcon={true}
                  username={inactiveReferral.login}
                  email={inactiveReferral.email}
                />
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};
