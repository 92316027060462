import smartXAbi from './abis/abi.json';
import allocateAbi from './abis/abiAllocate.json';
import depositAbi from './abis/abiDeposit.json';

export const SmartXLogicAbi = smartXAbi;
export const ABIDeposit = depositAbi;
// NOTE: can be removed. Not used anymore.
export const ABIAllocate = allocateAbi;

export * from './shared';
export * from './validations';
export * from './sidebar';
export * from './finance';
export * from './faq';
