import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';

import { getTransactions } from 'infrastructure/api/requests';

import { ViewContent } from 'components/atoms';

type TransactionType =
  | 'deposit'
  | 'withdrawal'
  | 'profit_income'
  | 'referral_income'
  | 'binary_income';

const getTransactionType = (type: TransactionType) => {
  const types = {
    deposit: 'Deposit',
    withdrawal: 'Withdrawal',
    profit_income: 'Profit Income',
    referral_income: 'Referral Income',
    binary_income: 'Binary Income',
  };

  return types[type];
};

const TableRow = ({ referralAccrual }: any) => {
  return (
    <div className="border-t border-lightBlack py-4 first:border-t-0">
      <div className="grid grid-cols-2 gap-x-10 md:grid-cols-4 md:gap-5">
        <div>
          <h3 className="text-xs font-semibold text-white">
            {referralAccrual.amount}
          </h3>
        </div>
        <div>
          <h3 className="mb-1 max-w-[100px] text-xs text-white/40 md:mb-0 md:max-w-[140px] md:text-white">
            {dayjs(referralAccrual.createdAt).format('DD/MM/YYYY HH:mm:ss')}
          </h3>
        </div>
        <div>
          <div className="flex items-center justify-between">
            <p className="text-xs text-success md:text-white">
              {getTransactionType(referralAccrual.type)}
            </p>
          </div>
        </div>
        <div>
          <div className="flex items-center justify-between">
            <p className="flex items-center text-xs text-success">
              {' '}
              <span className="mr-2.5 block h-[6px] w-[6px] rounded-full bg-lSuccess"></span>{' '}
              Completed
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export const FinaceDetailedTable = () => {
  const { data, isLoading, isError } = useQuery({
    queryKey: ['userTransactionsDefaultView'],
    queryFn: getTransactions,
  });

  return (
    <>
      <div className="mb-4 flex items-center justify-between">
        <h3 className="text-lg font-semibold text-white md:mb-0">
          Detailed table{' '}
        </h3>
      </div>
      <div>
        <div className="hidden grid-cols-4 border-b border-white/40 py-3 md:grid">
          <div className="text-xs text-white/40">Amount</div>
          <div className="text-xs text-white/40">Date</div>
          <div className="text-xs text-white/40">Type</div>
          <div className="text-xs text-white/40">Status</div>
        </div>
        <div className="max-h-[466px] overflow-y-auto pr-5">
          <ViewContent
            isError={isError}
            isLoading={isLoading}
            data={data && data.data ? [data.data] : []}
          >
            {(data: any) =>
              data[0].map((referralAccrual: any) => (
                <TableRow
                  key={referralAccrual.id}
                  referralAccrual={referralAccrual}
                />
              ))
            }
          </ViewContent>
        </div>
      </div>
    </>
  );
};
