import React from 'react';
import classNames from 'classnames';

import { useUserContext } from 'infrastructure/features/common/context/user.context';

export const IncomeProgress = () => {
  const { user } = useUserContext();

  const currentPercentProgress = Number(user?.withdrawnPercentage ?? 0);
  const filledProgressPercent = currentPercentProgress / 3.03;

  return (
    <div className="flex w-full flex-col p-5 md:mt-0 md:p-6">
      <h2 className="mb-4 text-lg font-semibold text-white">Income progress</h2>
      <div className="flex flex-row">
        <div>
          <h3 className="text-sm font-normal text-[#948B2C]">Start</h3>
          <p className="text-lg font-semibold text-[#FFFFFF66]/40">0%</p>
        </div>
        <div className="mx-2 h-full w-[1px] bg-white/10 md:mx-7"></div>
        <div className="w-full">
          <h3 className="text-sm font-normal text-[#948B2C]">
            Current achievement
          </h3>
          <div className="relative">
            <h5 className="textShadow absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-lg font-semibold text-white">
              {currentPercentProgress}%
            </h5>
            <div className="flex h-7 items-center rounded-md bg-white/10">
              {[...new Array(33)].map((_, i) => (
                <div
                  key={i}
                  className={classNames(
                    'ml-[2px] h-[24px] w-full min-w-[3.5px] rounded-[4px] last:mr-[2px]',
                    i <= filledProgressPercent ? 'bg-[#F7EA10]' : 'bg-white/10',
                  )}
                />
              ))}
            </div>
          </div>
        </div>
        <div className="mx-2 h-full w-[1px] bg-white/10 md:mx-7"></div>
        <div>
          <h3 className="text-sm font-normal text-[#948B2C]">Max</h3>
          <p className="text-lg font-semibold text-[#FFFFFF66]/40">300%</p>
        </div>
      </div>
    </div>
  );
};
