import axios from 'axios';
import Cookies from 'js-cookie';

import { clearSession } from 'domain/services';

import { Auth } from 'lib/types';

// pass here some config and interceptors
const host = window.location.host;
const url = host.includes('.net')
  ? process.env.REACT_APP_API_URL_NET
  : process.env.REACT_APP_API_URL;
const apiInstance = axios.create({
  baseURL: url,
  // baseURL: process.env.REACT_APP_API_TEST_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

apiInstance.interceptors.request.use(
  (config) => {
    const token = Cookies.get(Auth.TOKEN);

    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }

    return config;
  },
  (error) => Promise.reject(error),
);

// Response interceptor for API calls
apiInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const config = error?.config;

    if (error?.response?.status === 401 && !config.sent) {
      config.sent = true;
      const refreshToken = Cookies.get(Auth.REFRESH) as string;

      // @ts-expect-error
      const { data } = await axios
        .post(url + '/token/refresh', {
          refreshToken,
        })
        .catch((err) => {
          if (err.response.status === 401) {
            clearSession();
            return;
          }
        });

      if (data?.token) {
        Cookies.set(Auth.TOKEN, data.token);
        config.headers = {
          ...config.headers,
          authorization: `Bearer ${data.token}`,
        };
      }
      return apiInstance(config);
    }

    return Promise.reject(error);
  },
);

export default apiInstance;
