import { Box } from 'components/atoms';
import {
  HomeInfoCards,
  HomeOverallBalance,
  HomeReferralInfo,
  HomeReferralsEarnings,
} from 'components/organisms';

const Dashboard = () => {
  return (
    <div className="relative grid grid-cols-1 gap-5 md:grid-cols-4">
      <Box className="!border-dYellow relative z-20 col-span-1 !p-0 md:col-span-4 md:h-[180px]">
        <HomeOverallBalance />
      </Box>
      <Box className="referralBlockInfoGradient !border-dYellow relative z-20 col-span-1 !rounded-2xl md:col-span-2">
        <HomeReferralInfo />
      </Box>
      <Box className="relative z-20 col-span-1 !border-none !p-0 md:col-span-2">
        <HomeReferralsEarnings />
      </Box>
      <Box className="relative z-20 col-span-1 mb-5 !border-none !p-0 md:col-span-4">
        <HomeInfoCards />
      </Box>
    </div>
  );
};

export default Dashboard;
