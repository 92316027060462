import ReactDOM from 'react-dom/client';

import './index.css';

import { ErrorBoundary } from 'react-error-boundary';

import App from './App';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <ErrorBoundary
    fallback={
      <div>
        Currently undergoing maintenance, please wait until the site update
        finishes
      </div>
    }
  >
    <App />
  </ErrorBoundary>,
);
