import { useAccount, useWalletClient } from 'wagmi';

import { useEthersProvider } from './useEthersProvider';
import { useEthersSigner } from './useEthersSigner';

export const useActiveWeb3Client = () => {
  const provider = useEthersProvider();
  const signer = useEthersSigner();
  const walletClient = useWalletClient();
  const account = useAccount();

  const chainId = account.chainId;
  const address = account.address;

  const isAccountLoading = account.isConnecting || account.isReconnecting;
  const isWalletClientLoading = walletClient.isLoading;
  const isWeb3ClientReadyForUse =
    !walletClient.isLoading && !account.isConnecting && !account.isReconnecting;
  // provider?.on("block")
  return {
    address,
    chainId,
    isAccountLoading,
    isWalletClientLoading,
    isWeb3ClientReadyForUse,
    walletClient,
    provider,
    signer,
  };
};
