import { FC, PropsWithChildren } from 'react';
import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';

interface ModalProps {
  open: boolean;
  className?: string;
  handleClose: () => void;
  showCloseButton?: boolean;
}

// TODO: fix modal height for small macbooks sizes
export const Modal: FC<PropsWithChildren<ModalProps>> = ({
  children,
  className,
  open,
  handleClose,
  showCloseButton = true,
}) => {
  return (
    <AnimatePresence>
      {open && (
        <>
          <motion.div
            className={classNames(
              'fixed left-1/2 z-[51] m-auto -translate-x-1/2 -translate-y-1/2 rounded-lg bg-lightBlack p-5 md:max-w-[580px]',
              className && className,
            )}
            key="modal"
            initial={{ top: '30%', opacity: 0 }}
            animate={{ top: '50%', opacity: 1 }}
            exit={{ top: '20%', opacity: 0 }}
          >
            {showCloseButton && (
              <div
                onClick={handleClose}
                className="absolute right-5 top-5 z-20"
              >
                <div className="cursor-pointer">
                  <img
                    src="/icons/close.svg"
                    alt="close icon"
                  />
                </div>
              </div>
            )}
            <div className="max-h-[400px] overflow-x-auto md:max-h-full">
              {children}
            </div>
          </motion.div>
          <motion.div
            onClick={handleClose}
            className={classNames(
              'fixed bottom-0 left-0 right-0 top-0 z-50 bg-[#212121] opacity-100',
            )}
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.6 }}
            exit={{ opacity: 0 }}
          />
        </>
      )}
    </AnimatePresence>
  );
};
