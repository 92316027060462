import { FC } from 'react';

import { Box } from 'components/atoms';

import { copyClipboard } from 'lib/utils';

import { Button } from './Button';

interface ReferralShareInformationProps {
  withButton?: boolean;
  link: string;
  title: string;
}

export const ReferralShareInformation: FC<ReferralShareInformationProps> = ({
  withButton,
  link,
  title,
}) => {
  return (
    <Box className="!border-[#F7EA10] bg-[#000]/80">
      <h3 className="mb-4 font-normal text-[#948B2C]">{title}</h3>
      <div className="flex flex-row">
        <div className="flex w-full items-center justify-between rounded-[12px]">
          <input
            disabled
            value={link}
            className="w-full pr-5 text-sm text-lGrey"
          />
        </div>
        {/* TODO: figure out what exactly should be done with this button  */}
        {withButton && (
          <Button
            type="button"
            className="flex max-w-[25px] items-center justify-center !py-0 hover:shadow-none md:ml-[10px] md:mt-0"
            onClick={() => {
              copyClipboard(link);
            }}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M20.25 3H8.25C8.05109 3 7.86032 3.07902 7.71967 3.21967C7.57902 3.36032 7.5 3.55109 7.5 3.75V7.5H3.75C3.55109 7.5 3.36032 7.57902 3.21967 7.71967C3.07902 7.86032 3 8.05109 3 8.25V20.25C3 20.4489 3.07902 20.6397 3.21967 20.7803C3.36032 20.921 3.55109 21 3.75 21H15.75C15.9489 21 16.1397 20.921 16.2803 20.7803C16.421 20.6397 16.5 20.4489 16.5 20.25V16.5H20.25C20.4489 16.5 20.6397 16.421 20.7803 16.2803C20.921 16.1397 21 15.9489 21 15.75V3.75C21 3.55109 20.921 3.36032 20.7803 3.21967C20.6397 3.07902 20.4489 3 20.25 3ZM15 19.5H4.5V9H15V19.5ZM19.5 15H16.5V8.25C16.5 8.05109 16.421 7.86032 16.2803 7.71967C16.1397 7.57902 15.9489 7.5 15.75 7.5H9V4.5H19.5V15Z"
                fill="#FAEF71"
              />
            </svg>
          </Button>
        )}
      </div>
    </Box>
  );
};
