import { useNavigate } from 'react-router';
import classNames from 'classnames';

import { useUserContext } from 'infrastructure/features/common/context/user.context';

import { BalanceSizeBox } from 'components/atoms';

export const HomeOverallBalance = () => {
  const navigate = useNavigate();
  const { user } = useUserContext();

  const handleDepositRedirect = () => navigate('/finance?type=deposit');

  const currentPercentProgress = Number(user?.withdrawnPercentage ?? 0);
  const filledProgressPercent = currentPercentProgress / 3.03;

  return (
    <div className="overallBalanceGradient flex h-full flex-col justify-between md:flex-row">
      <BalanceSizeBox
        title="Balance"
        size={user?.balanceAmount ?? '0'}
        className="w-full !rounded-xl md:w-[340px]"
      />

      <div className="my-14 flex items-center justify-center xl:my-0">
        <button
          className="relative z-30 flex items-center rounded-[80px] bg-[#FCF6A9] p-4 text-lg font-bold uppercase text-black shadow-actionShadow transition-all hover:scale-95 hover:bg-[#FAEF71]"
          onClick={handleDepositRedirect}
        >
          <img
            className="absolute -right-[40px] bottom-0"
            src="/icons/depositButtonTop.svg"
            alt="depositDotsTop"
          />
          <div className="relative z-40">
            <div className="mr-2 flex h-10 w-10 items-center justify-center rounded-full bg-[#000]">
              <svg
                width="18"
                height="16"
                viewBox="0 0 18 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.75 8.75H17.25C17.6642 8.75 18 8.41421 18 8C18 7.58579 17.6642 7.25 17.25 7.25H0.75C0.335786 7.25 0 7.58579 0 8C0 8.41421 0.335786 8.75 0.75 8.75Z"
                  fill="#FBF395"
                />
                <path
                  d="M16.1893 8L9.96967 14.2197C9.82902 14.3603 9.75 14.5511 9.75 14.75C9.75 14.9489 9.82902 15.1397 9.96967 15.2803C10.1103 15.421 10.3011 15.5 10.5 15.5C10.6989 15.5 10.8897 15.421 11.0303 15.2803L17.7803 8.53033C18.0732 8.23744 18.0732 7.76256 17.7803 7.46967L11.0303 0.71967C10.8897 0.579018 10.6989 0.5 10.5 0.5C10.3011 0.5 10.1103 0.579018 9.96967 0.71967C9.82902 0.860322 9.75 1.05109 9.75 1.25C9.75 1.44891 9.82902 1.63968 9.96967 1.78033L16.1893 8Z"
                  fill="#FBF395"
                />
              </svg>
            </div>
          </div>
          <span>Make Deposit</span>
          <img
            className="absolute -left-[40px] top-0"
            src="/icons/depositButtonBottom.svg"
            alt="depositDotsBottom"
          />
        </button>
      </div>
      <div className="flex flex-col p-6 md:mt-0 md:w-[426px] md:border-l md:border-[#948B2C]/30">
        <h2 className="mb-4 text-lg font-semibold text-white">
          Income progress
        </h2>
        <div className="flex flex-row">
          <div>
            <h3 className="text-sm font-normal text-[#948B2C]">Start</h3>
            <p className="text-lg font-semibold text-[#FFFFFF66]/40">0%</p>
          </div>
          <div className="mx-2 h-full w-[1px] bg-white/10 md:mx-7"></div>
          <div className="w-[176px] md:w-auto">
            <h3 className="text-sm font-normal text-[#948B2C]">
              Current achievement
            </h3>
            <div className="relative">
              <h5 className="textShadow absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-lg font-semibold text-white">
                {currentPercentProgress}%
              </h5>
              <div className="flex h-7 w-[176px] items-center rounded-md bg-white/10">
                {[...new Array(33)].map((_, i) => (
                  <div
                    key={i}
                    className={classNames(
                      'ml-[2px] h-[24px] w-[3.8px] rounded-[4px] last:mr-[2px]',
                      i <= filledProgressPercent
                        ? 'bg-[#F7EA10]'
                        : 'bg-white/10',
                    )}
                  />
                ))}
              </div>
            </div>
          </div>
          <div className="mx-2 h-full w-[1px] bg-white/10 md:mx-7"></div>
          <div>
            <h3 className="text-sm font-normal text-[#948B2C]">Max</h3>
            <p className="text-lg font-semibold text-[#FFFFFF66]/40">300%</p>
          </div>
        </div>
      </div>
    </div>
  );
};
