import { HTMLInputTypeAttribute } from 'react';
import { UseFormRegister, WatchInternal } from 'react-hook-form';
import classNames from 'classnames';

import { useToggle } from 'infrastructure/features/common/hooks';

interface InputProps {
  label?: string | JSX.Element;
  id: string;
  type: HTMLInputTypeAttribute;
  placeholder?: string;
  LabelIcon?: JSX.Element;
  LabelHint?: JSX.Element;
  InputIcon?: JSX.Element;
  register: UseFormRegister<any>;
  errorMessage?: string;
  className?: string;
  containerClassname?: string;
  disabled?: boolean;
  maxLength?: number;
  minLength?: number;
  watch?: WatchInternal<string>;
  description?: string;
}

export const Input = ({
  placeholder,
  errorMessage,
  type,
  id,
  disabled,
  maxLength,
  minLength,
  register,
  watch,
  className,
  label,
  containerClassname,
  description,
}: InputProps) => {
  watch && watch(id);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isShowPassword, togglePassword] = useToggle();

  return (
    <div className={classNames('flex flex-col', containerClassname)}>
      {label && (
        <label
          htmlFor={id}
          className="mb-[6px] cursor-pointer text-sm text-lGrey"
        >
          {label}
        </label>
      )}
      {type !== 'password' ? (
        <input
          placeholder={placeholder}
          className={classNames(
            'disabled:text-lightGrey w-full rounded-xl border border-lightBorder bg-lBlack p-4 text-sm text-lGrey outline-none transition-all placeholder:text-sm placeholder:text-placeholder focus:border-secondaryButton',
            className && className,
            errorMessage && '!border-red',
          )}
          type={type}
          id={id}
          autoComplete="false"
          disabled={disabled}
          min={minLength}
          max={maxLength}
          step="any"
          formNoValidate
          {...register(id)}
        />
      ) : (
        <div className="relative">
          <input
            placeholder={placeholder}
            className={classNames(
              'disabled:text-lightGrey w-full rounded-xl border border-lightBorder bg-lBlack p-4 text-sm text-lGrey outline-none transition-all placeholder:text-sm placeholder:text-placeholder focus:border-secondaryButton',
              className && className,
              errorMessage && '!border-red',
            )}
            type={isShowPassword ? 'text' : type}
            id={id}
            autoComplete="off"
            disabled={disabled}
            min={minLength}
            max={maxLength}
            {...register(id)}
          />
          {/* {showIcon && (
            <div
              onClick={togglePassword}
              className={classNames(
                "absolute top-[11px] right-[10px] text-white cursor-pointer",
                iconClassName && iconClassName
              )}
            >
              <img
                className="h-[15px] w-[25px]"
                src={isShowPassword ? "/icons/eyeClosed.svg" : "/icons/eye.svg"}
                alt="eye"
              />
            </div>
          )} */}
        </div>
      )}
      {errorMessage && (
        <div className="mb-4 text-[12px] text-red">{errorMessage}</div>
      )}
      {description && (
        <p className="mb-5 mt-[10px] text-xs font-semibold text-grey">
          {description}
        </p>
      )}
    </div>
  );
};
