import { useFormContext } from 'react-hook-form';
import { Tree, TreeNode } from 'react-organizational-chart';
import classNames from 'classnames';

import { Loader } from 'components/atoms';
import { InfoIconRoot, InfoIconUser, MoreDots } from 'components/Icons';

import { Nullable, ReferralTreeDataProps } from 'lib/types';

export const renderTreeNode = (
  node: Nullable<ReferralTreeDataProps>,
  handleSelectReferral: (referralUser: ReferralTreeDataProps) => void,
  setSelectedTreeElement: any,
) => {
  if (!node) {
    return (
      <TreeNode
        label={
          <div className="m-auto w-[182px] rounded-lg bg-[#131313] p-4">
            <div className="mb-2 flex items-center justify-between">
              <p className="overflow-hidden text-ellipsis font-semibold text-[#fff]">
                -
              </p>
            </div>
            <p className="overflow-hidden text-ellipsis text-left text-[#646464]">
              -
            </p>
          </div>
        }
      />
    );
  }

  const renderTreeNextElements = () => {
    if (node.leftChild || node.rightChild) {
      return (
        <>
          {renderTreeNode(
            node.leftChild,
            handleSelectReferral,
            setSelectedTreeElement,
          )}
          {renderTreeNode(
            node.rightChild,
            handleSelectReferral,
            setSelectedTreeElement,
          )}
        </>
      );
    }

    if (node.hasMoreChildren) {
      return (
        <div className="flex w-full items-center justify-center">
          <MoreDots />
        </div>
      );
    }

    return null;
  };

  return (
    <TreeNode
      label={
        <div className="m-auto w-[150px] rounded-lg bg-[#000]/40 p-4">
          <div className="mb-2 flex items-center justify-between">
            <p
              className={classNames(
                'overflow-hidden text-ellipsis font-semibold text-[#fff]',
                { 'cursor-pointer': node.id },
              )}
              onClick={() => node.id && setSelectedTreeElement(node)}
            >
              {node.login}
            </p>
            {node.id && (
              <div
                className={classNames(
                  'hover:[&>*]:!stroke-[#fff] hover:[&>svg]:[&>circle]:stroke-white',
                  { 'cursor-pointer': node.id },
                )}
                onClick={() => node.id && handleSelectReferral(node)}
              >
                <InfoIconUser />
              </div>
            )}
          </div>
          <p
            className={classNames(
              'overflow-hidden text-ellipsis text-left text-[#646464]',
              { 'cursor-pointer': node.id },
            )}
            onClick={() => node.id && setSelectedTreeElement(node)}
          >
            {node.email}
          </p>
        </div>
      }
    >
      {renderTreeNextElements()}
    </TreeNode>
  );
};

export const OrganizationalTree = ({
  data,
  handleSelectReferral,
  setSelectedTreeElement,
  selectedTreeElement,
  topUser,
  binaryStats,
  isReferralsLoadingUserStats,
}: {
  data: ReferralTreeDataProps;
  handleSelectReferral: (referralUser: ReferralTreeDataProps) => void;
  setSelectedTreeElement: any;
  selectedTreeElement: any;
  topUser: any;
  binaryStats: any;
  isReferralsLoadingUserStats: boolean;
}) => {
  const { setValue } = useFormContext();

  return (
    <>
      <Tree
        lineWidth={'2px'}
        lineColor={'#FEE30A'}
        lineBorderRadius={'2px'}
        label={
          // root-tree-element - styles are in css file, since target is next sibling's ::before element from library
          <div
            className={classNames(
              'root-tree-element m-auto max-w-[182px] rounded-lg bg-main p-4',
              { 'mt-[240px]': selectedTreeElement },
            )}
          >
            <div className="mb-2 flex items-center justify-between">
              <p className="overflow-hidden text-ellipsis font-semibold text-[#191919]">
                {data?.login}
              </p>
              <div
                className="cursor-pointer"
                onClick={() => handleSelectReferral(data)}
              >
                <InfoIconRoot />
              </div>
            </div>
            <p className="overflow-hidden text-ellipsis text-left text-[#404040]">
              {data?.email}
            </p>
          </div>
        }
      >
        {selectedTreeElement && (
          <div className="absolute -top-[306px] left-1/2 flex -translate-x-1/2 flex-col items-center">
            <div
              className={classNames(
                'root-tree-element m-auto w-[182px] rounded-lg p-4',
                selectedTreeElement ? 'bg-[#131313]' : 'bg-main',
              )}
            >
              <div className="mb-2 flex items-center justify-between">
                <p
                  className={classNames(
                    'cursor-pointer overflow-hidden text-ellipsis font-semibold text-[#191919]',
                    { 'text-[#fff]': selectedTreeElement },
                  )}
                  onClick={() => {
                    setValue('selectedTreeUser', null);
                    setSelectedTreeElement(null);
                  }}
                >
                  {topUser.login}
                </p>
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    handleSelectReferral(topUser);
                  }}
                >
                  <InfoIconUser />
                </div>
              </div>
              <p
                className={classNames(
                  'cursor-pointer overflow-hidden text-ellipsis text-left text-[#404040]',
                  { 'text-[#646464]': selectedTreeElement },
                )}
                onClick={() => {
                  setValue('selectedTreeUser', null);
                  setSelectedTreeElement(null);
                }}
              >
                {topUser.email}
              </p>
            </div>
            <div className="h-[90px] w-[2px] border bg-white" />
            <MoreDots />
            <div
              className="h-[20px] w-[2px] border bg-[#FEE30A]"
              key={'mainLine'}
            />
          </div>
        )}

        <div className="absolute left-[25%] top-[5px] flex flex-col md:top-5 md:w-[201px]">
          {!isReferralsLoadingUserStats ? (
            <>
              <div className="mb-2 flex flex-col items-center justify-between text-sm md:flex-row">
                <p>Active referrals:</p>{' '}
                <p className="font-semibold text-main">
                  {binaryStats?.leftLegStats?.activeReferrals}
                </p>{' '}
              </div>
              <div className="flex flex-col items-center justify-between text-sm md:flex-row">
                <p>Turnover structure:</p>{' '}
                <p className="font-semibold text-main">
                  ${binaryStats?.leftLegStats?.turnover}
                </p>{' '}
              </div>
            </>
          ) : (
            <Loader className="!bg-white" />
          )}
        </div>

        <div className="absolute right-[25%] top-[5px] flex flex-col md:top-5 md:w-[201px]">
          {!isReferralsLoadingUserStats ? (
            <>
              <div className="mb-2 flex flex-col items-center justify-between text-sm md:flex-row">
                <p>Active referrals:</p>{' '}
                <p className="font-semibold text-[#FF8126]">
                  {binaryStats?.rightLegStats?.activeReferrals}
                </p>{' '}
              </div>
              <div className="flex flex-col items-center justify-between text-sm md:flex-row">
                <p>Turnover structure:</p>{' '}
                <p className="font-semibold text-[#FF8126]">
                  ${binaryStats?.rightLegStats?.turnover}
                </p>{' '}
              </div>
            </>
          ) : (
            <Loader className="!bg-white" />
          )}
        </div>
        {renderTreeNode(
          data.leftChild,
          handleSelectReferral,
          setSelectedTreeElement,
        )}
        {renderTreeNode(
          data.rightChild,
          handleSelectReferral,
          setSelectedTreeElement,
        )}
      </Tree>
    </>
  );
};
