import { FC, useEffect, useState } from 'react';
import FlipNumbers from 'react-flip-numbers';

import { CounterSvgElement } from './CounterSvgElement';

interface FlipperNumbersProps {
  activeInvestment: number;
  hasAnyActiveInvestments?: boolean;
  percent: string;
}

const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

// ebanina.
const dividers = [
  {
    id: 'first',
    className: 'cls-18',
    d: 'm232,458.5V70.5h-83v.224c-11.133,4.823-20,89.73-20,193.776s8.867,188.953,20,193.776v.224h83Z',
    foreignObject: { x: 135, y: 200, width: 99.991, height: 388 },
  },
  { id: 'cls-15', x: 238, y: 69.5, width: 103.991, height: 388 },
  { id: 'cls-17', x: 344.501, y: 69.5, width: 103.991, height: 388 },
  { id: 'cls-25', x: 451.002, y: 69.5, width: 104.991, height: 388 },
  { id: 'cls-26', x: 557.503, y: 69.5, width: 103.991, height: 388 },
  { id: 'cls-22', x: 664.004, y: 69.5, width: 103.991, height: 388 },
  { id: 'cls-16', x: 770.504, y: 69.5, width: 103.991, height: 388 },
  { id: 'cls-20', x: 877.005, y: 69.5, width: 103.991, height: 388 },
  { id: 'cls-23', x: 983.506, y: 69.5, width: 103.991, height: 388 },
  { id: 'cls-21', x: 1090.007, y: 69.5, width: 103.991, height: 388 },
  { id: 'cls-14', x: 1196.508, y: 69.5, width: 101.991, height: 388 },
  { id: 'cls-19', x: 1303.009, y: 69.5, width: 101.991, height: 388 },
  {
    id: 'last',
    className: 'cls-27',
    d: 'm1408,458.5V70.5h83v.224c11.133,4.823,20,89.73,20,193.776s-8.867,188.953-20,193.776v.224h-83Z',
    foreignObject: { x: 1408, y: 200, width: 99.991, height: 388 },
  },
];

export const FlipperNumbers: FC<FlipperNumbersProps> = ({
  activeInvestment,
  hasAnyActiveInvestments,
  percent,
}) => {
  const [investment, setInvestment] = useState<number>(activeInvestment);

  useEffect(() => {
    const interval = setInterval(() => {
      if (hasAnyActiveInvestments) {
        setInvestment((prevInvestment) => {
          return prevInvestment + Number(percent);
        });
      }
    }, 1000);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasAnyActiveInvestments]);

  const numberString = investment.toFixed(13).toString();

  return (
    <div className="relative m-auto w-full max-w-[680px]">
      <CounterSvgElement>
        <g id="dividers">
          {dividers.map((rect, index) => {
            const value = numberString[index];
            const isNumber = Number.isInteger(Number(value));

            if (rect.d) {
              const path = rect;

              return (
                <g key={path.id}>
                  <path
                    id={path.id}
                    className={path.className}
                    d={path.d}
                    fill="gold"
                  />
                  <foreignObject
                    x={path.foreignObject.x}
                    y={69.5}
                    width={path.foreignObject.width}
                    height={path.foreignObject.height}
                  >
                    <div className="flex h-full items-center justify-center font-tektur font-bold">
                      {isIOS ? (
                        <div className="mt-4 w-full text-center font-tektur text-[94px] text-black">
                          {value}
                        </div>
                      ) : (
                        <FlipNumbers
                          height={95}
                          width={80}
                          color="#181A20"
                          background="transparent"
                          play
                          perspective={450}
                          numbers={value}
                        />
                      )}
                    </div>
                  </foreignObject>
                </g>
              );
            }

            const foreignObjectWidth = rect.width;
            const foreignObjectHeight = rect.height;
            const foreignObjectX =
              // @ts-expect-error
              rect.x + (rect.width - foreignObjectWidth) / 2;
            const foreignObjectY =
              // @ts-expect-error
              rect.y + (rect.height - foreignObjectHeight) / 2;

            return (
              <g key={rect.id}>
                <rect
                  className={rect.id}
                  x={rect.x}
                  y={rect.y}
                  width={rect.width}
                  height={rect.height}
                />
                <foreignObject
                  x={foreignObjectX}
                  y={foreignObjectY}
                  width={foreignObjectWidth}
                  height={foreignObjectHeight}
                >
                  <div className="flex h-full items-center justify-center font-tektur font-bold">
                    {isNumber ? (
                      isIOS ? (
                        <div className="mt-4 w-full text-center font-tektur text-[94px] text-black">
                          {value}
                        </div>
                      ) : (
                        <FlipNumbers
                          height={95}
                          width={80}
                          color="#181A20"
                          background="transparent"
                          play
                          perspective={450}
                          numbers={value}
                        />
                      )
                    ) : (
                      <div className="mt-4 w-full text-center font-tektur text-[94px] text-black">
                        {value}
                      </div>
                    )}
                  </div>
                </foreignObject>
              </g>
            );
          })}
        </g>
      </CounterSvgElement>
    </div>
  );
};
