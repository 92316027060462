import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router';
import classNames from 'classnames';

import { mobileMenuItems } from 'const/mobileMenu';

export const MobileMenu = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = (link: string) => {
    navigate(link);
  };

  const isActivePage = useCallback(
    (link: string) => {
      return location.pathname.split('/')[1] === link.split('/')[1];
    },
    [location],
  );

  return (
    <div className="fixed bottom-[-1px] z-40 flex w-full justify-between bg-[#000000] px-5 pb-2 pt-2 xl:hidden">
      {mobileMenuItems.map(({ link, icon, title }) => (
        <button
          key={title}
          onClick={() => handleClick(link)}
          className={classNames(
            'flex h-[44px] w-[44px] shrink flex-col items-center justify-center rounded-2xl text-xs font-semibold leading-tight text-grey transition-all',
            isActivePage(link)
              ? 'bg-lYellow'
              : 'hover:bg-white/5 active:bg-white/10',
          )}
        >
          <div
            className={classNames(
              isActivePage(link)
                ? '[&>svg]:!fill-[#000]'
                : '[&>svg]:fill-white',
            )}
          >
            {icon}
          </div>
        </button>
      ))}
    </div>
  );
};
