import { useUserContext } from 'infrastructure/features/common/context/user.context';

import { BalanceSizeBox } from 'components/atoms';

interface FinanceBalanceBlockProps {
  handleDepositRedirect: () => void;
}

export const FinanceBalanceBlock = ({
  handleDepositRedirect,
}: FinanceBalanceBlockProps) => {
  const { user } = useUserContext();

  const balanceSize = user?.balanceAmount ?? '0';

  return (
    <div className="flex flex-wrap md:min-h-[220px]">
      <BalanceSizeBox
        title="Balance"
        size={balanceSize}
        className="w-full !rounded-xl xl:w-[340px]"
      />

      <div className="mx-auto my-14 flex items-center justify-center 2xl:my-0">
        <button
          className="relative z-30 flex items-center rounded-[80px] bg-[#FCF6A9] p-4 text-lg font-bold uppercase text-black shadow-actionShadow transition-all hover:scale-95 hover:bg-[#FAEF71]"
          onClick={handleDepositRedirect}
        >
          <img
            className="absolute -right-[40px] bottom-0 block md:hidden 2xl:block"
            src="/icons/depositButtonTop.svg"
            alt="depositDotsTop"
          />
          <div className="relative z-40">
            <div className="mr-2 flex h-10 w-10 items-center justify-center rounded-full bg-[#000]">
              <svg
                width="18"
                height="16"
                viewBox="0 0 18 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.75 8.75H17.25C17.6642 8.75 18 8.41421 18 8C18 7.58579 17.6642 7.25 17.25 7.25H0.75C0.335786 7.25 0 7.58579 0 8C0 8.41421 0.335786 8.75 0.75 8.75Z"
                  fill="#FBF395"
                />
                <path
                  d="M16.1893 8L9.96967 14.2197C9.82902 14.3603 9.75 14.5511 9.75 14.75C9.75 14.9489 9.82902 15.1397 9.96967 15.2803C10.1103 15.421 10.3011 15.5 10.5 15.5C10.6989 15.5 10.8897 15.421 11.0303 15.2803L17.7803 8.53033C18.0732 8.23744 18.0732 7.76256 17.7803 7.46967L11.0303 0.71967C10.8897 0.579018 10.6989 0.5 10.5 0.5C10.3011 0.5 10.1103 0.579018 9.96967 0.71967C9.82902 0.860322 9.75 1.05109 9.75 1.25C9.75 1.44891 9.82902 1.63968 9.96967 1.78033L16.1893 8Z"
                  fill="#FBF395"
                />
              </svg>
            </div>
          </div>
          <span>Make Deposit</span>
          <img
            className="absolute -left-[40px] top-0 block md:hidden 2xl:block"
            src="/icons/depositButtonBottom.svg"
            alt="depositDotsBottom"
          />
        </button>
      </div>
    </div>
  );
};
