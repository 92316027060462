export const InformationAboutConfirmation = () => {
  return (
    <p className="text-white text-justify mt-7 border border-lightBlack p-4 rounded-lg bg-lightBlack">
      <strong>Please sign the request in your wallet to continue.</strong>
      <br />
      <br />
      To proceed, you need to approve the signature request that has been sent
      to your wallet. Open your wallet and follow the instructions to sign the
      request.
    </p>
  );
};
