import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import classNames from 'classnames';
import dayjs from 'dayjs';

import apiInstance from 'infrastructure/api';
import { useUserContext } from 'infrastructure/features/common/context/user.context';

import {
  Box,
  Button,
  Countdown,
  FinanceEarnings,
  IncomeProgress,
} from 'components/atoms';
import { CounterXElement, ProfitHand } from 'components/Icons';
import {
  DividendBonusesTicker,
  FinanceBalanceBlock,
} from 'components/molecules';
import { FinaceDetailedTable } from 'components/organisms';

import { FinanceTabType } from 'lib/types';

export const FinanceDefaultView = () => {
  const navigate = useNavigate();
  const { user, refetchUserProfile, isBannedUser } = useUserContext();

  const handleButtonNavigation = (url: string) => navigate(url);

  const targetTime = dayjs(user?.nextProfitClaimTime);
  const now = dayjs();

  const withdrawnPercentage = Number(user?.withdrawnPercentage);

  const availableForClaim = targetTime.diff(now, 'millisecond') < 0;

  useEffect(() => {
    refetchUserProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleProfit = async () => {
    if (isBannedUser) return;

    try {
      await apiInstance.post(`/claim-profit`);
      toast.success('Funds were claimed');

      window.location.reload();
    } catch (error) {
      console.error(error);
      toast.error('Error while claiming');
    }
  };

  const isReachedLimit = withdrawnPercentage === 100;

  const handleDepositRedirect = () => {
    handleButtonNavigation(`?type=${FinanceTabType.DEPOSIT}`);
  };

  return (
    <div className="mb-5 grid grid-cols-1 gap-5 lg:grid-cols-4">
      <div className="order-2 col-span-1 flex flex-col lg:order-1 lg:col-span-2">
        <Box className="overallBalanceGradient relative z-20 !border-dYellow !p-0">
          <FinanceBalanceBlock handleDepositRedirect={handleDepositRedirect} />
        </Box>
        <Box className="overallBalanceGradient relative z-20 mt-7 flex flex-grow-[2] items-center justify-center !border-dYellow !p-6">
          <FinanceEarnings />
        </Box>
        <Box className="overallBalanceGradient relative z-20 mt-7 flex h-[140px] items-center justify-center !border-dYellow !p-0 2xl:h-[173px]">
          <IncomeProgress />
        </Box>
      </div>
      <div className="order-1 col-span-1 lg:order-2 lg:col-span-2">
        <Box className="!border-dYellow">
          <h2 className="mb-4 text-center text-lg font-semibold text-white">
            Counter passive income
          </h2>
          <div className="relative">
            <div className="mx-auto max-w-[548px]">
              <CounterXElement />
            </div>
            <div className="absolute left-1/2 top-1/2 w-full -translate-x-1/2 -translate-y-1/2">
              <DividendBonusesTicker />
            </div>
          </div>

          {+(user?.availableWithdrawalAmount ?? 0) > 0 && (
            <>
              {user?.referredATP ? (
                <div className="flex h-[50px] w-full items-center justify-center pt-5 font-tektur text-3xl [&>span]:!text-main">
                  <Countdown
                    endTime={user!.nextProfitClaimTime}
                    referredATP={user!.referredATP}
                  />
                </div>
              ) : (
                <Button
                  type="button"
                  className={classNames(
                    'mx-auto !mt-4 flex max-w-[260px] items-center justify-center !rounded-2xl !bg-[#FAEF71] bg-dMain py-4 font-tektur text-xl text-main transition-all hover:shadow-main disabled:cursor-not-allowed disabled:!border-black disabled:!bg-lightBlack disabled:!text-grey md:mt-0',
                    availableForClaim && 'cursor-pointer',
                    isReachedLimit &&
                      'disabled:cursor-not-allowed disabled:!border-black disabled:!bg-lightBlack disabled:!text-grey',
                  )}
                  onClick={handleProfit}
                  disabled={!availableForClaim || isReachedLimit}
                >
                  {availableForClaim ? (
                    <>
                      <ProfitHand />
                      <span className="text-lg font-semibold text-[#000]">
                        Take Profit
                      </span>
                    </>
                  ) : (
                    <Countdown
                      endTime={user!.nextProfitClaimTime}
                      referredATP={user!.referredATP}
                    />
                  )}
                </Button>
              )}
            </>
          )}
        </Box>
      </div>

      <Box className="detailedTable order-3 col-span-1 bg-[#1E1E1E66]/40 lg:col-span-4">
        <FinaceDetailedTable />
      </Box>
    </div>
  );
};
