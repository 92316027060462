import { useMemo } from 'react';
import { InterfaceAbi } from 'ethers';

import { getContract } from 'domain/services';

import { useActiveWeb3Client } from './useActiveWeb3Client';

export const useContract = (contractAddress: string, abi: InterfaceAbi) => {
  const { signer } = useActiveWeb3Client();

  const contractInstance = useMemo(() => {
    if (!contractAddress || !signer) {
      return null;
    }

    const contract = getContract(contractAddress, abi, signer as any);

    return contract;
  }, [contractAddress, signer, abi]);

  return contractInstance;
};
