import { useUserContext } from 'infrastructure/features/common/context/user.context';
import { useAuth } from 'infrastructure/features/common/hooks';

import { SignOut } from 'components/Icons';
import { SidebarMenuItem } from 'components/molecules';

import { menuItems } from 'const';

export const Sidebar = () => {
  const { handleSignout } = useAuth();
  const { user } = useUserContext();

  return (
    <div className="fixed -left-full z-50 h-full w-full max-w-[212px] self-stretch bg-[#000]/40 py-5 transition-all xl:fixed xl:left-0 xl:h-auto">
      <aside className="sticky top-2 h-[calc(100vh-40px)]">
        <div className="relative mb-5 xl:mb-0"></div>
        <div className="hidden flex-col p-5 pb-[28px] xl:flex">
          <a
            href="https://smart-x.world"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className="m-auto mb-8 w-[200px]"
              src="/images/smartx-logo.svg"
              alt="SmartX logo"
            />
          </a>
          <h2 className="mb-[6px] overflow-hidden text-ellipsis text-sm font-semibold text-white">
            Hello, {user?.firstName}
          </h2>
          <p className="overflow-hidden text-ellipsis text-sm font-semibold text-white/40">
            ({user?.login})
          </p>
        </div>

        <ul className="mb-10">
          {menuItems.map((item) => (
            <SidebarMenuItem
              key={item.title}
              item={item}
            />
          ))}
        </ul>

        <div className="absolute bottom-0 w-full">
          {/* <button
      className="mt-5 text-left text-white text-sm rounded-xl border border-white/20 w-full max-w-[180px] mx-auto px-2 py-3 flex items-center justify-between"
      type="button"
      onClick={() => {
        console.log("lng change");
      }}
    >
      <div className="flex">
        <SignOut /> <span className="ml-2">English</span>
      </div>{" "}
      <ChevronDown />
    </button> */}
          <button
            className="mx-auto mt-2 flex w-full max-w-[180px] items-center rounded-xl border border-white/20 px-2 py-3 text-left text-sm text-white transition-all hover:bg-main/20"
            type="button"
            onClick={handleSignout}
          >
            <SignOut /> <span className="ml-2">Logout</span>
          </button>
        </div>
      </aside>
    </div>
  );
};
