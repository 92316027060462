import { FC, PropsWithChildren } from 'react';
import { createPortal } from 'react-dom';

import { usePortal } from 'infrastructure/features/common/hooks';

interface PortalProps {
  id: string;
}

export const Portal: FC<PropsWithChildren<PortalProps>> = ({
  id,
  children,
}) => {
  const target = usePortal(id);
  return createPortal(
    children,
    // @ts-expect-error
    target,
  );
};
