import { useLocation } from 'react-router';

import { FinanceDefaultView, FinanceDepositView } from 'components/organisms';

import { FinanceTabType } from 'lib/types';

export const Finance = () => {
  const location = useLocation();
  const tabFromURL = location.search.replace('?type=', '');

  const renderLayout = () => {
    const layout = {
      [FinanceTabType.DEPOSIT]: <FinanceDepositView />,
    };

    return (
      // @ts-expect-error
      layout[tabFromURL] || <FinanceDefaultView />
    );
  };

  return renderLayout();
};
